import Axios from 'axios';
import CryptoJS from 'crypto-js';
import Vue from 'vue';
import VueRouter from 'vue-router';
import Vuelidate from 'vuelidate';

import { BrowserAgent } from '@newrelic/browser-agent/loaders/browser-agent';

import MotivationCodes from '@/libraries/AnanNet/MotivationCodes';

import { Routes } from './routes';

import App from './App.vue';

import '@fortawesome/fontawesome-free/js/all.min.js';
import './sass/vendor.scss';
import './sass/main.scss';




// Environment
Vue.config.productionTip = process.env.NODE_ENV !== 'production';

// Global Instance Properties
Vue.prototype.$application = {
	name: process.env.VUE_APP_TITLE,
	organization: process.env.VUE_APP_ORG
}

Vue.prototype.$http = Axios.create({
	baseURL: process.env.VUE_APP_BASE_API_URL
});

Vue.prototype.$crypto = CryptoJS;

Vue.prototype.$captcha = window.grecaptcha;
Vue.prototype.$captcha.siteKey = process.env.VUE_APP_RECAPTCHA_KEY;

Vue.use(VueRouter);
Vue.use(Vuelidate);

const router = new VueRouter({
	mode: 'history',
	routes: Routes,
	base: process.env.BASE_URL,
	linkExactActiveClass: 'active'
});

// NEW RELIC AGENT
const newRelicOptions = {
	init: {
		distributed_tracing:{
			enabled:true,
			cors_use_newrelic_header:true,
			cors_use_tracecontext_headers:true,
			allowed_origins: [process.env.VUE_APP_NEW_RELIC_ALLOWED_ORIGINS],
		},
		privacy:{cookies_enabled:true},
		ajax:{deny_list:["bam.nr-data.net"]}
	},
	info: { 
		beacon:"bam.nr-data.net",
		errorBeacon:"bam.nr-data.net",
		licenseKey:"NRJS-59dea2f9ad1cc30a8b3",
		applicationID: process.env.VUE_APP_NEW_RELIC_APPLICATION_ID,
		sa:1
	},
	loader_config: { 
		accountID:"4154585",
		trustKey:"4154585",
		agentID: process.env.VUE_APP_NEW_RELIC_APPLICATION_ID,
		licenseKey:"NRJS-59dea2f9ad1cc30a8b3",
		applicationID:process.env.VUE_APP_NEW_RELIC_APPLICATION_ID
	}
}

Vue.prototype.$newRelicAgent = new BrowserAgent(newRelicOptions);

router.beforeEach((to, from, next) => {

	Vue.prototype.$application.motivationCode = new MotivationCodes(to.query.s_subsrc).getCode();

    if (to.meta.title === undefined) {

		document.title = Vue.prototype.$application.name + ' | ' + Vue.prototype.$application.organization;
    }
    else {

		document.title = to.meta.title + ' | ' + Vue.prototype.$application.organization;
	}

	next();
})



new Vue({
	router,
	render: h => h(App)
}).$mount('#app');