import CookieStorage from '@/libraries/CookieStorage.js';

class MotivationCodes {

	constructor (motivationCode) {

		this.codes = {
			default: 'EGM0000XXEXFM',
			google: 'ESG0000XXEXXX',
			bing: 'ESB0000XXEXXX',
			yahoo: 'ESN0000XXEXXX'
		};

		this.cookie = {
			name: 'motivCode',
			expire: 90,
			domain: 'ifcj.org'
		};

		this.cookieStorage = new CookieStorage();

		this.setCode(motivationCode);

		this.cookieStorage.setItem(this.cookie.name, this.motivationCode, this.cookie.expire, this.cookie.domain);
	}

	getCode () {

		return this.motivationCode;
	}

	setCode (motivationCode) {

		if (motivationCode !== undefined && motivationCode !== '') {

			this.motivationCode = motivationCode;
		}
		else if (this.cookieStorage.getItem(this.cookie.name) !== null && this.cookieStorage.getItem(this.cookie.name) !== '') {

			this.motivationCode = this.cookieStorage.getItem(this.cookie.name);
		}
		else if (document.referrer.indexOf('google.com') !== -1) {

			this.motivationCode = this.codes.google;
		}
		else if (document.referrer.indexOf('bing.com') !== -1) {

			this.motivationCode = this.codes.bing;
		}
		else if (document.referrer.indexOf('yahoo.com') !== -1) {

			this.motivationCode = this.codes.yahoo;
		}
		else {

			this.motivationCode = this.codes.default;
		}
	}
}

export default MotivationCodes;
