<template>
	<div class="maintenance">

		<div class="row">

			<div class="col-12 text-center">

				<a v-bind:href="logos[maintenance.language].url">
					<img v-bind:src="logos[maintenance.language].source" alt="International Fellowship of Christians and Jews" title="International Fellowship of Christians and Jews" class="img-fluid" />
				</a><br /><br />

				<h1 class="title mb-4" v-html="maintenance.title"></h1>

			</div>

		</div>

		<div class="row">

			<div class="col-12">

				<div class="content">
					<div class="alert alert-warning text-center">
						<p>This page is currently down for maintenance. This work will be finished soon, but our holy work at The Fellowship is not complete. Check back in a few minutes. Thank you for your patience!</p>
					</div>
				</div>

			</div>

		</div>

	</div>
</template>

<script>
import logos from '@/assets/data/logos.json';

export default {
	name: 'Maintenance',
	data () {
		return {
			logos: logos,
			maintenance: {
				language: 'en',
				pageTitle: 'Under Maintenance',
				title: 'Under Maintenance',

			}
		}
	},
	created () {

		document.title = this.maintenance.pageTitle + ' | ' + document.title;

		
	}
}
</script>

<style lang="scss" scoped>
</style>
