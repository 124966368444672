<template>
	<div class="acknowledgment">

		<div class="row">

			<div class="col-12 text-center">

				<a v-bind:href="logos[acknowledgment.language].url">
					<img v-bind:src="logos[acknowledgment.language].source" alt="International Fellowship of Christians and Jews" title="International Fellowship of Christians and Jews" class="img-fluid" />
				</a><br /><br />

				<h1 class="title mb-4" v-html="acknowledgment.title"></h1>

			</div>

		</div>

		<div class="row">

			<div class="col-12">

				<div class="content">

					<p><em>Shalom</em>,</p>
					<p>The depth of your ongoing compassion for the Jewish people inspires us each and every day! Thank you for making an even bigger impact by increasing your monthly gift to ${{acknowledgment.totalAmount}} in support of The Fellowship's lifesaving ministries. Your increased gift amount will be reflected in your next monthly deduction.</p>
					<p>Because of you, eyes will brighten, tears will flow, and hearts will be lifted.</p>
					<p><em>Todah Rabah</em> &ndash; thank you so much for being a true blessing to the Jewish people and to The Fellowship.</p>
					<p>We know that God is faithful to His Word: <em>"I will bless those who bless you [Israel]""</em> (Genesis 12:3).</p>
					<p>If you have any questions, please contact our Donor Support team at 1-800-486-8844 between the hours of 8AM – 5PM CST, Monday- Friday. It would be a pleasure to speak with you.</p>

					<br />

					<p><em>Shalom,</em><br/>
					The Fellowship</p>

				</div>

			</div>

		</div>

	</div>
</template>

<script>
import logos from '@/assets/data/logos.json';

export default {
	name: 'UpgradeAcknowledgment',
	data () {
		return {
			logos: logos,
			acknowledgment: {
				language: 'en',
				pageTitle: 'Thank You for Increasing Your Impact',
				title: 'Thank You for Increasing Your Impact',
				totalAmount: null,
				nextGiftDate : null
			}
		}
	},
	created () {

		document.title = this.acknowledgment.pageTitle + ' | ' + document.title;

		if (sessionStorage.getItem('acknowledgment') !== null) {
			
			let acknowledgment = JSON.parse(window.sessionStorage.getItem('acknowledgment'));
			this.acknowledgment.totalAmount = acknowledgment.totalAmount;
			this.acknowledgment.nextGiftDate = acknowledgment.nextGiftDate;

			sessionStorage.removeItem('acknowledgment');
		}
	}
}
</script>

<style lang="scss" scoped>
</style>
