<template>

	<div class="donation-upgrade">

		<section class="introduction">

			<div class="row">

				<div class="col-12 text-center">

					<a v-bind:href="logos[firmCancel.language].url">
						<img v-bind:src="logos[firmCancel.language].source" alt="International Fellowship of Christians and Jews" title="International Fellowship of Christians and Jews" class="img-fluid mb-3" />
					</a>

					<h1 v-html="firmCancel.title"></h1>

				</div>

			</div>

		</section>

		<section class="content">

			<div class="row">

				<div  class="col-sm-12 col-md-5">

					<section class="introduction">
						<!--<img  src="https://media.ifcj.org/form-media/images/df-14701256.jpg" class="img-fluid mb-3" />-->
						<p>Thank you for taking just a few moments to answer this short survey. Your answers will help us learn and grow so we can continue to heed God’s call to <em>“Comfort ye, comfort ye my people”</em> (Isaiah 40:1).</p>
					</section>

				</div>

				<div class="col-sm-12 col-md-7">

					<section class="information">

						<form class="firmCancelForm" novalidate v-on:submit.prevent="postFirmCancel" v-bind:class="{'was-validated': isSubmitted}" >

							<div class="form-row">

                                <div class="form-group col-md-12">
                                    <label for="question1">{{form.question1.label}}</label>
                                    <select id="question1" v-model="form.question1.selected" @change="showOther" class="custom-select">
										<option v-for="option in form.question1.options" :key="option.id" :value="option.value">{{option.value}}</option>   
                                    </select>
                                    <textarea id="otherText" class="form-control mt-3" maxlength="250" v-model="form.question1.selected" v-if="showOtherBox" ></textarea>
                                </div>
							</div>

							<div class="form-row">

								<div class="form-group col-md-12">
                                    <label for="question2">{{form.question2.label}}</label>
                                    <select id="question2" v-model="form.question2.selected" class="custom-select">
										<option v-for="option in form.question2.options" :key="option.id" :value="option.value">{{option.value}}</option>   
                                    </select>
                                </div>

							</div>

                            <div class="form-row">

								<div class="form-group col-md-12">
                                    <label for="question3">{{form.question3.label}}</label>
                                    <select id="question3" v-model="form.question3.selected" class="custom-select">
										<option v-for="option in form.question3.options" :key="option.id" :value="option.value">{{option.value}}</option>   
                                    </select>
                                </div>

							</div>

                            <div class="form-row">

								<div class="form-group col-md-12">
                                    <label for="question4">{{form.question4.label}}</label>
                                    <select id="question4" v-model="form.question4.selected" class="custom-select">
										<option v-for="option in form.question4.options" :key="option.id" :value="option.value">{{option.value}}</option>   
                                    </select>
                                </div>

							</div>
                            <div class="form-row">

								<div class="form-group col-md-12">
                                    <label for="question5">{{form.question5.label}}</label>
                                    <textarea id="question5" maxlength="250" class="form-control" v-model="form.question5.text"></textarea>
									
                                </div>

							</div>

							<section class="submit">

								<div class="alert alert-danger text-center" v-show="message != ''">{{message}}</div>

								<div class="form-row justify-content-center">
									<div class="col-xs-12 col-md-6">
										<button type="button" class="btn btn-primary btn-lg btn-block" v-on:click="postFirmCancel" v-bind:disabled="isProcessing">
											Submit
											<span v-show="isProcessing"><i class="fas fa-spinner fa-pulse ml-2"></i></span>
										</button>
									</div>
								</div>

							</section>

						</form>

					</section>
				</div>

			</div>
			
		</section>

	</div>

</template>

<script>
import logos from '@/assets/data/logos.json';

export default {
	name: 'SurveyFirmCancel',
	data () {
		return {
			logos: logos,
			motivationCode: this.$application.motivationCode,
			firmCancel: {
				subscriberKey: this.$route.params.subscriberKey,
				entityId: this.$route.params.entityId,
				transactionId: this.$route.params.transactionId,
				language: 'en',
				redirectUrl: '/firm-cancel/thankyou',
				title: 'Your answers will help us learn and grow',
			},
			form: {
                question1:{
					label:'What inspired you to start giving monthly to The Fellowship?',
					options:[
						{id:'option1', value:'I like the convenience'},
						{id:'option2', value:'I wanted to feel more connected'},
						{id:'option3', value:'It\'s easier for me to budget'},
						{id:'option4', value:'I felt led — it was a step of faith'},
						{id:'option5', value:'It was my (or part of my) tithe to the Lord'},
						{id:'option6', value:'Other'}
					],
					selected: null,
				},
                 question2:{
					label:'Did The Fellowship do a good job at telling you how much impact your monthly gift made?',
					options:[
						{id:'option1', value:'Yes'},
						{id:'option2', value:'No'},
						{id:'option3', value:'Somewhat'}
					],
					selected: null,
				},
				question3:{
					label:'As a monthly giver, did you feel like part of a special Fellowship community?',
					options:[
						{id:'option1', value:'Yes'},
						{id:'option2', value:'No'},
						{id:'option3', value:'Somewhat'}
					],
					selected: null,
				},
                 question4:{
					label:'What aspect of The Fellowship\'s mission is most important to you?',
					options:[
						{id:'option1', value:'Helping Holocaust survivors'},
						{id:'option2', value:'Helping Jews in the former Soviet Union'},
						{id:'option3', value:'Safety & security in Israel'},
						{id:'option4', value:'Helping Jews immigrate to Israel (aliyah)'},
						{id:'option5', value:'Helping orphans, children, and families'},
					],
					selected: null,
				},

                question5: {
					label:'How could The Fellowship have improved your experience as a monthly giver?',
					text: ''
				} 
			},
			isProcessing: false,
			isSubmitted: false,
			isSuccess: false,
			message: '',
            otherText: '',
            showOtherBox: false
            
		}
	},
	created () {

		
	},
	watch: {
		
	},

	methods: {

        showOther () {

            this.showOtherBox = this.form.question1.selected == 'Other';
     
        },
		
		postFirmCancel () {

            this.isSubmitted = true;
			this.isProcessing = true;

			this.$captcha.ready(() => {
				this.$captcha.execute(this.$captcha.siteKey, {action: 'GenericSurvey'})
				.then((token) => {

					let config = {
						headers: {
							'reCAPTCHA-Token': token
						}
					};

					let	payload = {
						entityId: this.firmCancel.entityId,
						transactionId: this.firmCancel.transactionId,
						subscriberKey: this.firmCancel.subscriberKey,
						motivationCode: this.motivationCode,
                        question1: {
							question: this.form.question1.label,
							answer: this.form.question1.selected
						},
                        question2: {
							question: this.form.question2.label,
							answer: this.form.question2.selected
						},
                        question3: {
							question: this.form.question3.label,
							answer: this.form.question3.selected
						},
                        question4: {
							question: this.form.question4.label,
							answer: this.form.question4.selected
						},
                        question5: {
							question: this.form.question5.label,
							answer: this.form.question5.text
						},

					};

					this.$http.post('/forms/survey/firmCancel', payload, config)
					.then((response) => {
						

						if (response.data.success) {

							this.isSuccess = true;
							this.isProcessing = false;

														
							window.location = this.firmCancel.redirectUrl;
						}
						else {

							this.isSuccess = false;
							this.isProcessing = false;
							this.message = 'Something went wrong, please contact our donor support team at (800) 486-8844 or info@ifcj.org if you have trouble completing your survey.';

							this.$newRelicAgent.noticeError(response.data.message, {
								component: 'SurveyFirmCancel',
								method: 'postFirmCancel',
								type: 'UserException'
							});
						}


					})
					.catch((error) => {

						this.isSuccess = false;
						this.isProcessing = false;
						this.message = error;
					
					});
				});

			});
		}
	}
}
</script>

<style lang="scss" scoped>
.introduction, .content {

	margin-bottom: 2rem;
}
</style>
