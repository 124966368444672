<template>

	<div class="survey-generic">

		<div class="row">

			<div class="col-12 text-center">

				<a v-bind:href="logos[survey.language].url">
					<img v-bind:src="logos[survey.language].source" alt="International Fellowship of Christians and Jews" title="International Fellowship of Christians and Jews" class="img-fluid mb-3" />
				</a>

				<h1 class="title mb-4" v-html="survey.title"></h1>

			</div>

		</div>

		<div v-if="survey.status == 1">

			<form class="survey" novalidate v-on:submit.prevent="checkAddress"  v-bind:class="{'was-validated': isSubmitted}">

				<div class="row">

					<div class="col-sm-12 col-md-6">

						<section class="introduction">

							<div class="row">

								<div class="col-md-12">

									<Media v-if="survey.mediaType != 0" v-bind:media-type="survey.mediaType" v-bind:media="survey.media" class="mb-3" />

									<div v-if="survey.content != ''" v-html="survey.content"></div>

								</div>

							</div>

						</section>

					</div>

					<div class="col-sm-12 col-md-6">

						<section class="information">
							<div v-if="this.$route.params.primaryId == 130">
			
								<h4>As a Christian, I will stand with and support the Jewish state and the Jewish people, for as the Bible says, the Jewish people are my people and their God is my God.</h4>
		
							</div>

							<SurveyContact v-bind:contact="contact" v-on:update:contact="contact = $event" v-bind:validation="$v" v-bind:hasSMSField="survey.hasSMSField" v-bind:default-country="contact.country" v-on:update:note="note = $event" v-bind:language="survey.language" v-bind:is-submitted="isSubmitted" v-bind:form-size="survey.formSize" v-bind:has-note-field="survey.hasNoteField" v-bind:address-checked="survey.isAddressChecked" v-bind:note-label="survey.noteLabel"/>

							<section class="subscriptions" v-if="survey.subscriptions.length > 0">

								<label>{{fieldNames[survey.language].subscriptions}}</label>
								

								<label v-for="(subscription, index) in survey.subscriptions" v-bind:key="index">
									<input type="checkbox" name="subscriptions" v-model="subscriptions" v-bind:value="subscription.name">
									<span class="label-text" v-html="subscription.name"></span>
								</label>

							</section>

						</section>

						<section class="submit">

							<div class="alert alert-danger text-center" v-if="isSubmitted && survey.language == 'en' && message != ''">{{message}}</div>
							<div class="alert alert-danger text-center" v-if="isSubmitted && survey.language == 'es' && messageES != ''">{{messageES}}</div>
							<div class="alert alert-danger text-center" v-if="isSubmitted && survey.language == 'pt' && messagePT != ''">{{messagePT}}</div>

							<div class="form-row justify-content-center">
								<div class="col-xs-12 col-sm-6 col-md-8">
									<button type="submit" class="btn btn-primary btn-lg btn-block" v-bind:disabled="isProcessing">
										{{survey.buttonText}}
										<span v-show="isProcessing"><i class="fas fa-spinner fa-pulse ml-2"></i></span>
									</button>
								</div>
							</div>

						</section>

						<section v-if="this.$route.params.primaryId == 130" class="alert alert-light border mt-4">

							<p class="m-0"><strong>Please sign your name in NOW so we can bring these names to Israel, to let the Jewish people know they are not alone but have Christian friends who stand with them in word, prayer, and action.</strong></p>
						
						</section>


					</div>

				</div>

			</form>

		</div>

		<div v-else-if="survey.status == 5">

			<div class="alert alert-primary text-center">
				We're sorry, but this offer is no longer available as we had a limited supply for this promotion. You can still show your support for Israel and the Jewish people by requesting a <a style="font-weight: 700;" href="https://help.ifcj.org/survey/81/0">U.S.-Israel Flag Pin</a>.
			</div>

		</div>

		<div class="modal" tabindex="-1" role="dialog" v-bind:class="{active: modal.addressCheck.status.isActive}">
				<div class="modal-dialog" role="document">
					<div class="modal-content">
						<div class="modal-header">
							<h5 class="modal-title">VERIFY YOUR ADDRESS <i class="fa fa-map-marker" aria-hidden="true"></i></h5>

								<button type="button" class="close" data-dismiss="modal" aria-label="Close" v-on:click="closeModal">
									<span aria-hidden="true">&times;</span>
								</button>

						</div>
						<div class="modal-body">
							<p>{{this.modal.addressCheck.message}}</p>

							<div class="row" v-if="this.modal.addressCheck.status.isSuccess">

								<div class="col-12">

									<div class="input-option" v-bind:class="{selected: this.modal.addressCheck.selected == 2}" v-on:click="setAddress(2)" >

										<div class="input-button">
											<div class="option-radio">
												<div v-show="this.modal.addressCheck.selected == 2"><i class="fas fa-circle"></i></div>
											</div>
										</div>

										<div class="input-text mt-3 ml-3">
											<span>RECOMMENDED ADDRESS </span>
											<p>{{this.modal.addressCheck.addressReturned.street}} <br> {{this.modal.addressCheck.addressReturned.city}}, {{this.modal.addressCheck.addressReturned.state}} {{this.modal.addressCheck.addressReturned.zipcode}} </p>
										</div>

									</div>
									
								</div>

								<div class="col-12">
									<div class="input-option" v-bind:class="{selected: this.modal.addressCheck.selected == 1}" v-on:click="setAddress(1)" >

										<div class="input-button">
											<div class="option-radio">
												<div v-show="this.modal.addressCheck.selected == 1"><i class="fas fa-circle"></i></div>
											</div>
										</div>

										<div class="input-text mt-3 ml-3">
											<span>YOU ENTERED</span>
											<p>{{this.contact.streetAddress}} <br> {{this.contact.city}}, {{this.contact.state}} {{this.contact.postalCode}}</p>
										</div>
									</div>

									<center><button  v-if="this.modal.addressCheck.status.isSuccess" type="button" class="btn btn-solid-primary mt-3" v-bind:disabled="isProcessing" v-on:click="submitAddress()">SUBMIT <span v-show="isProcessing"><i class="fas fa-spinner fa-pulse ml-2"></i></span></button></center>

								</div>

							</div>
							<div class="input-text text-center" v-if="this.modal.addressCheck.status.isSuccess == false">
								
								<span>YOU ENTERED:</span>
								<p>{{this.contact.streetAddress}} <br> {{this.contact.city}}, {{this.contact.state}} {{this.contact.postalCode}}</p>
								<button  v-if="this.modal.addressCheck.status.isSuccess == false" type="button" class="btn btn-solid-primary" v-on:click="closeModal">CORRECT YOUR ADDRESS<br>(recommended)</button>
								<button  style="text-decoration: underline;" type="button" class="btn btn-block btn-link mt-2" v-bind:disabled="isProcessing" v-on:click="postSurvey()"><span>Use Address As Entered</span></button>
							</div>
						</div>
								
					</div>
				</div>
		</div>

	</div>

</template>

<script>
import fieldNames from '@/assets/data/fieldnames.json';
import disclaimers from '@/assets/data/disclaimers.json';
import formFieldErrors from '@/assets/data/formfielderrors.json';
import Media from '@/components/Media.vue';
import SurveyContact from '@/views/partials/Forms/SurveyContact.vue';

import logos from '@/assets/data/logos.json';
import logosTest from '@/assets/data/logos-test.json';
import logosNascar from '@/assets/data/logos-nascar.json';
import logosHannity from '@/assets/data/logos-hannity.json';
import logosSirius from '@/assets/data/logos-sirius.json';
import logosWava from '@/assets/data/logos-wava.json';
import logosIheart from '@/assets/data/logos-iheart.json';
import logosMGallagher from '@/assets/data/logos-mike-gallagher.json';

import { minLength, maxLength, numeric, required, requiredIf } from 'vuelidate/lib/validators';

export default {
	name: 'SurveyGeneric',
	components: {
		Media, SurveyContact
	},
	data () {
		return {
			logos: this.$route.query.version == 2 ? logosTest : this.$route.query.version == 3 ? logosNascar : this.$route.query.version == 4 ? logosHannity : this.$route.query.version == 5 ? logosSirius : this.$route.query.version == 6 ? logosWava : this.$route.query.version == 7 ? logosIheart : this.$route.query.version == 8 ? logosMGallagher : logos,
			fieldNames: fieldNames,
			disclaimers: disclaimers,
			formFieldErrors: formFieldErrors,
			note: '',
			subscriptions: [],
			isProcessing: false,
			isSubmitted: false,
			isSuccess: false,
			message: '',
			survey: {
				emailId: this.$route.query.emid === undefined ? '' : this.$route.query.emid,
				motivationCode: this.$application.motivationCode,
				hasSMSField: false,
				layoutType: null,
				language: 'en',
				subscriptions: [],
				status: null,
				isAddressChecked: false
			},
			contact: {
				firstName: '',
				lastName: '',
				streetAddress: '',
				city: '',
				state: '',
				country: 'US',
				postalCode: '',
				emailAddress: '',
				phoneNumber: '',
				sms: false
			},
			modal: {
				addressCheck: {
					message:'',
					selected: 2,
					addressReturned:{
						street:'',
						city: '',
						state: '',
						zipcode: ''
					},
					status: {
						isActive: false,
						isSuccess: false
					}
				}
			}
			
		}
	},
	validations () {
		
		return {
			contact: {

				firstName: {
					required,
					maxLength: maxLength(20)
				},
				lastName: {
					required,
					maxLength: maxLength(20)
				},
				emailAddress: {
					required,
					maxLength: maxLength(50)
				},
				phoneNumber: {
					required: requiredIf(() => {
						return this.survey.formSize !== 3
					}),
					numeric,
					maxLength: maxLength(13)
				},
				streetAddress: {
					required: requiredIf(() => {
						return this.survey.formSize == 1
					}),
					maxLength: maxLength(35)
				},
				city: {
					required: requiredIf(() => {
						return this.survey.formSize == 1
					}),
					maxLength: maxLength(30)
				},
				state: {
					required: requiredIf(() => {
						return this.survey.formSize == 1
					}),
					minLength: minLength(2),
					maxLength: maxLength(13)
				},
				postalCode: {
					required: requiredIf(() => {
						return this.survey.formSize == 1
					}),
					minLength: minLength(5),
					maxLength: maxLength(10)

				},
				country: {
					required: requiredIf(() => {
						return this.survey.formSize == 1
					})
				}
			},
		}
	},

	created () {

		this.getSurvey(this.$route.params.primaryId, this.$route.params.secondaryId);
		
	},
	
	methods: {

		setAddress (selected) {

			this.modal.addressCheck.selected = selected;
			
		},

		submitAddress () {
			
			if(this.modal.addressCheck.selected == 2) {
				
				this.updateAddress();
				
			} else {

				this.keepExistingAddress();
			}
			
			return false;

		},

		checkAddress(){

			this.isSubmitted = true;
			this.isProcessing = true;

			if (this.$v.$invalid) {

				this.message = 'The form could not be submitted. Please review the fields above and resubmit.';
				this.messageES ='No se pudo enviar el formulario. Revise los campos anteriores y vuelva a enviarlos.';
				this.messagePT = 'Não se pode enviar o formulário, revisar os campos anteriores e voltar a enviá-los';

				this.isProcessing = false;

				return false;
			}
			
			if (this.survey.formSize == 1 && this.contact.country == 'US') {

			
				let payload = {
					contact: this.contact,
				};

				this.$http.post('/forms/survey/checkAddress', payload)
					.then((response) => {

						this.isProcessing = false;

						if (response.data.success) {
							

							if(response.data.status == 'verified'){

								this.survey.isAddressChecked = true;
								this.postSurvey();
								return false;

							}
							
							this.modal.addressCheck.message = response.data.responseMessage;
							this.modal.addressCheck.addressReturned = response.data.deliveryAddress;
							this.modal.addressCheck.status.isSuccess = true;
							
							window.dataLayer.push({
								event: 'modal_event',
								modal_type: 'address_verification_'+response.data.status,
								modal_action: 'open'
							});

						} else {

								this.modal.addressCheck.message = response.data.responseMessage;
								this.modal.addressCheck.status.isSuccess = false;

								window.dataLayer.push({
									event: 'modal_event',
									modal_type: 'address_verification_'+response.data.status,
									modal_action: 'open'
								}); 


						}

							this.modal.addressCheck.status.isActive = true;	
							
					})
					.catch((error) => {

						this.modal.addressCheck.status.isActive = false;
						this.survey.isAddressChecked = false;	
						this.isProcessing = false;
						this.message = error;
					
					});

			} else {

				this.survey.isAddressChecked = false;
				this.postSurvey();
			}
			
		},

		updateAddress () {
			
			this.contact.streetAddress = this.modal.addressCheck.addressReturned.street;
			this.contact.city = this.modal.addressCheck.addressReturned.city;
			this.contact.state = this.modal.addressCheck.addressReturned.state;
			this.contact.postalCode = this.modal.addressCheck.addressReturned.zipcode;
			
			this.survey.isAddressChecked = true;

			if(this.modal.addressCheck.status.isSuccess) {

				window.dataLayer.push({
					event: 'modal_event',
					modal_type: 'address_verification',
					modal_action: 'submitted_recommended_address'
				});

				this.postSurvey();
			}

			this.modal.addressCheck.status.isActive = false;
		},

		keepExistingAddress () {

			this.survey.isAddressChecked = true;

			if(this.modal.addressCheck.status.isSuccess) {

				window.dataLayer.push({
					event: 'modal_event',
					modal_type: 'address_verification',
					modal_action: 'submitted_existing_address'
				});

				this.postSurvey();
			}
			
			this.modal.addressCheck.status.isActive = false;

		},

		closeModal () {
			
			window.dataLayer.push({
					event: 'modal_event',
					modal_type: 'address_verification',
					modal_action: 'closed'
			});

			this.modal.addressCheck.status.isActive = false;
		},

		getSurvey (primaryId, secondaryId) {

			this.$http.get('/forms/survey/' + primaryId + '/' + secondaryId)
				.then((response) => {

					if (response.data.success) {

						this.isSuccess = true;

						this.survey = Object.assign(this.survey, response.data.survey);

						document.title = this.survey.pageTitle + ' | ' + document.title;
					}
					else {

						this.isSuccess = false;
						this.message = 'We were unable to process this request. The form does not exist.';
						this.messageES = 'No pudimos procesar esta solicitud. El formulario no existe.';
						this.messagePT = 'Não foi possível processar esta solicitação. O formulário não existe.';

						this.$newRelicAgent.noticeError(response.data.message, {
							component: 'SurveyGeneric',
							method: 'getSurvey',
							type: 'UserException'
						});
					}
				})
				.catch ((error) => {

					this.isSuccess = false;
					this.message = error;

					this.$newRelicAgent.noticeError(error.response.data.message, {
						component: 'SurveyGeneric',
						method: 'getSurvey',
						type: 'ServerException'
				});
			});
		},

		postSurvey () {

			this.isSubmitted = true;
			this.isProcessing = true;

			if (this.$v.$invalid) {

				this.message = 'The form could not be submitted. Please review the fields above and resubmit.';
				this.messageES ='No se pudo enviar el formulario. Revise los campos anteriores y vuelva a enviarlos.';
				this.messagePT = 'Não se pode enviar o formulário, revisar os campos anteriores e voltar a enviá-los';

				this.isProcessing = false;

				return false;
			}

			this.$captcha.ready(() => {
				this.$captcha.execute(this.$captcha.siteKey, {action: 'GenericSurvey'})
					.then((token) => {

						let config = {
							headers: {
								'reCAPTCHA-Token': token
							}
						};

						let payload = {
							formId: this.survey.formId,
							primaryId: this.survey.primaryId,
							secondaryId: this.survey.secondaryId,
							emailId: this.survey.emailId,
							motivationCode: this.survey.motivationCode,
							contact: this.contact,
							note: this.note,
							subscriptions: this.subscriptions
						};

						this.modal.addressCheck.status.isActive = false;

						this.$http.post('/forms/survey', payload, config)
							.then((response) => {

								if (response.data.success) {

									window.dataLayer.push({
										event: 'SurveyComplete',
										sessionId: this.$crypto.SHA256(this.contact.emailAddress).toString(this.$crypto.enc.Hex),
										rb_session_id: this.$crypto.SHA1(this.contact.emailAddress).toString(this.$crypto.enc.Hex),
										fullEmailAddress: this.contact.emailAddress,
										motivationCode: this.motivationCode,
										smsOptIn: this.contact.sms,
										eventCategory: 'Surveys',
										eventAction: 'Submit',
										eventLabel: 'Conversion'
									});

									setTimeout(() => {

										window.location = this.survey.redirectUrl;
										this.isProcessing = false;
										
									}, 1000)

								}
								else {

									this.message = response.data.message;

									this.$newRelicAgent.noticeError(response.data.message, {
										component: 'SurveyGeneric',
										method: 'postSurvey',
										type: 'UserException'
									});
								}
							})
							.catch((error) => {

								this.isProcessing = false;

								this.message = error;

								this.$newRelicAgent.noticeError(error.response.data.message, {
									component: 'SurveyGeneric',
									method: 'postSurvey',
									type: 'ServerException'
								});
							});
					});
			});
		}
	}
}
</script>

<style lang="scss" scoped>
.introduction, .information {

	margin-bottom: 2rem;
}
.modal {

	display: block;
	background-color: rgba(0,0,0,.5);
	opacity: 0;
	visibility: hidden;
	transition: all .15s ease-in-out;
	position: fixed;

	&.active {
		opacity: 1;
		visibility: visible;
	}

	.modal-content {
		max-width: 400px;
	}

	.modal-body {
		position: relative;
		flex: 1 1 auto;
		padding: 1rem;
		background-color: #fcfafa;
		border-radius: 5px;
	
	}
	.input-text span {
			font-weight: bold;
		}
										
	
	
	.close {

		position: absolute;
		top: 1rem;
		right: 1rem;
		z-index: 1000;
		font-size: 1.5rem;
	}

	
	@media only screen and (max-width: 767) {

		.input-group {

			display: flex;
		}
		.modal-body {

			background:none;
			background-color: #fcfafa;

		}
	}

	@media only screen and (max-width: 480px) {

		.modal-body {

			background:none;
			background-color: #fcfafa;
		}
		.copy-block {

			margin-right: 0px;
		}

		.input-group {
			
			display: flex;
		}
	}

	@media only screen and (min-width: 576px) {

		.modal-dialog {

			margin: 1.75rem auto;
		}
	}
}
</style>
