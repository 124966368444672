<template>

	<footer>

		<div class="container">

			<div class="row justify-content-center">

				<div class="col-xs-12 col-sm-12 col-md-12 col-lg-9 col-xl-9">

					<div class="row">

						<div class="col-xs-12 col-sm-7 mb-3">

							<div class="company">
								
								<div class="address">

									<address itemprop="address" itemscope="" itemtype="http://schema.org/PostalAddress">
										<span itemprop="name">&copy; {{copyright}} International Fellowship of Christians and Jews<sup>&reg;</sup><br /></span>
										<span itemprop="streetAddress">303 East Wacker Drive, Suite 2300</span><br />
										<span itemprop="addressLocality">Chicago</span>, <span itemprop="addressRegion">IL</span>
										<span itemprop="postalCode"> 60601-5224</span><br />
										<span itemprop="telephone"><a href="tel:800-486-8844">800-486-8844</a></span>
										<span itemprop="alternateName" style="display:none;">The Fellowship</span>
										<span itemprop="alternateName" style="display:none;">IFCJ</span>
									</address>

									A 501(c)(3) tax-exempt, non-profit organization.<br />
									The Fellowship’s tax identification number is 36-3256096.

								</div>

							</div>

							<div class="copyright">

									<ul class="privacy mb-2">
										<li><a href="https://www.ifcj.org/privacy-policy">Privacy Policy</a></li>
										<li><a href="https://www.ifcj.org/donor-privacy-policy">Donor Privacy Policy</a></li>
										<li><a href="https://www.ifcj.ca/">Canada</a></li>
									</ul>

							</div>

						</div>

						<div class="col-xs-12 col-sm-5 text-md-right">
							
							<a href="https://help.ifcj.org/donate/16/0" class="btn btn-primary mb-3" data-site-region="footer" data-link-type="donate-button">Donate Now</a>
							
							<ul class="social d-flex justify-content-md-end">
								<li class="social-icon"><a href="https://www.facebook.com/FellowshipFan" target="_blank" title="Follow Us on Facebook"><i class="fab fa-facebook-f"></i></a></li>
								<li class="social-icon"><a href="https://twitter.com/TheFellowship" target="_blank" title="Follow Us on X-Twitter"><i class="fa-brands fa-x-twitter"></i></a></li>
								<li class="social-icon"><a href="https://www.instagram.com/the_fellowship" target="_blank" title="Follow Us on Instagram"><i class="fab fa-instagram"></i></a></li>
								<li class="social-icon"><a href="https://www.youtube.com/user/IFCJ25" target="_blank" title="Follow Us on YouTube"><i class="fab fa-youtube"></i></a></li>
								<li class="social-icon"><a href="https://www.linkedin.com/company/international-fellowship-of-christians-and-jews" target="_blank" title="LinkedIn"><i class="fab fa-linkedin-in"></i></a></li>
							</ul>
							<ul class="social d-flex justify-content-md-end mt-3">
								<li class="logo"><a href="https://www.ifcj.org/who-we-are/ratings-and-reviews">
									<img src="/static/images/logos/charity-navigator.png" loading="lazy" alt="Charity Navigator" target="_blank" rel="noreferrer" title="Charity Navigator"></a></li>
								<li class="logo ml-2"><a href="https://www.ifcj.org/who-we-are/ratings-and-reviews">
									<img src="/static/images/logos/candid-seal.png" loading="lazy" alt="GuideStar" target="_blank" rel="noreferrer" title="GuideStar"></a></li>
									<li class="logo ml-2"><a href="https://www.ifcj.org/who-we-are/ratings-and-reviews">
									<img src="/static/images/logos/bia-seal.png" loading="lazy" alt="America's Best Charities Award Logo" target="_blank" rel="noreferrer" title="America's Best Charities"></a></li>
								<li class="logo ml-2"><a href="https://www.ifcj.org/who-we-are/ratings-and-reviews">
									<img src="/static/images/logos/bbb.png" loading="lazy" alt="Better Business Bureau" target="_blank" rel="noreferrer" title="Better Business Bureau"></a></li>
							</ul>

						</div>

					</div>

				</div>

			</div>

		</div>

	</footer>

</template>



<script>
export default {
	name: 'Footer',
	data () { return { copyright: new Date().getFullYear() } }
}
</script>

<style lang="scss" scoped>
footer {

	background-color: #005b9e;
	padding: 2rem 0;
	font-size: 1rem;
	color: #fff;

	.navigation ul {

		list-style: none;
		padding: 0;
		margin: 0;
		margin-bottom: 2rem;

		li {

			margin-bottom: .25rem;

			&:last-of-type {

				margin-bottom: 0;
			}
		}
	}

	a {

		&:link,
		&:visited {

			font-weight: 700;
			color: #fff;
			text-decoration: none;
		}

		&:hover {

			color: #f0faa1;
		}
	}

	.company {

		display: flex;
		font-size: 1rem;
		margin-bottom: 2rem;

		.logo {

			padding-right: 1rem;
		}
	}

	.copyright {

		font-size: 1rem;

		.privacy,
		.flags {

			list-style: none;
			padding: 0;
			margin: 0;
		}

		.privacy li,
		.flags li {

			float: left;
		}

		.privacy li:not(:last-of-type)::after,
		.flags li:not(:last-of-type)::after {

			content: '|';
			margin: 0 5px;
		}
	}

	.social {

		list-style: none;
		padding: 0;
		margin: 0;

		li.social-icon {

			display: inline-block;
			margin-left: 5px;
			margin-bottom: 5px;

			a {

				display: flex;
				align-items: center;
				justify-content: center;
				width: 36px;
				height: 36px;
				font-size: 1.25rem;
				background-color: rgba(255, 255, 255, .75);
				transition: all .1s ease-in-out;

				&:link, &:visited {

					color: #005b9e;
				}

				&:hover, &:active {

					background-color: rgba(255, 255, 255, 1);
				}
			}
		}
	}
}
</style>

