<template>

	<div class="subscriptions-manage">

		<div class="row">

			<div class="col-12 text-center">

				<a v-bind:href="logos[language].url">
					<img v-bind:src="logos[language].source" alt="International Fellowship of Christians and Jews" title="International Fellowship of Christians and Jews" class="logo img-fluid" />
				</a>

			</div>

		</div>

		<h1>My Subscriptions</h1>

		<hr />

		<p>As a <em>Fellowship</em> Family, we love communicating with you, but respect your personal preferences. You can subscribe or unsubscribe by clicking on the button next to each email series. If you would like to unsubscribe to all, click the “Unsubscribe All” button at the bottom of the page.</p>

		<div class="alert text-center" v-if="status.isSubmitted && !status.isLoading && status.message != ''" v-bind:class="{'alert-success': status.isSubmitted && status.isSuccess && !status.isLoading, 'alert-danger': status.isSubmitted && !status.isSuccess && !status.isLoading}">
			{{status.message}}
		</div>

		<form id="subscriptions" novalidate v-bind:class="{'was-validated': status.isSubmitted}">

			<table class="table subscriptions">

				<thead class="thead-light">

					<tr>

						<th>Subscription</th>
						<th class="text-center">Status</th>

					</tr>

				</thead>

				<tbody>

					<tr v-if="subscriber.subscriptions.length === 0">

						<td colspan="2" class="text-center" v-show="status.isLoading">

							<i class="fas fa-spinner fa-pulse fa-2x"></i><br />
							<small>Loading Subscriptions</small>

						</td>

						<td colspan="2" class="text-center" v-show="!status.isSuccess">

							<i class="fas fa-exclamation-triangle fa-2x"></i><br />
							<small>Error Loading Data</small>

						</td>

						<td colspan="2" class="text-center" v-show="!status.isLoading && status.isSuccess">This user does not exist.</td>

					</tr>

					<tr v-for="(subscription, index) in subscriber.subscriptions" v-bind:key="index">

						<td>
							<strong>{{subscription.name}}</strong><br />
							{{subscription.description}}
						</td>
						<td class="status">
							<button type="button" class="btn btn-link"  v-if="subscription.status === true" v-on:click="subscription.status = false; unsubscribeSubscription(subscription.name)">Unsubscribe</button>
							<button type="button" class="btn btn-primary"  v-if="subscription.status === false" v-on:click="subscription.status = true; subscribeSubscription(subscription.name)">Subscribe</button>
						</td>

					</tr>

				</tbody>

			</table>

			<div class="text-center">
				<a v-bind:href="'/subscriptions/unsubscribe/' + this.$route.params.subscriberKey" class="btn btn-lg btn-primary">
					Unsubscribe All
				</a>
			</div>

		</form>

	</div>

</template>

<script>

import logos from '@/assets/data/logos.json';
import logosTest from '@/assets/data/logos-test.json';

export default {
	name: 'SubscriptionsManage',
	data () {
		return {
			subscriberKey: this.$route.params.subscriberKey,
			emailId: this.$route.query.emailId == undefined ? '' : this.$route.query.emailId == undefined,
			logos: this.$route.query.version == 2 ? logosTest : logos,
			language: 'en',
			subscriber: {
				subscriptions: []
			},
			status: {
				isSubmitted: false,
				isLoading: false,
				isSuccess: true,
				message: ''
			}
		}
	},
	created () {

		this.getSubscriptions();
	},
	methods: {

		getSubscriptions () {

			this.status.isLoading = true;

			this.$captcha.ready(() => {
				this.$captcha.execute(this.$captcha.siteKey, {action: 'Subscriptions'})
					.then((token) => {

						let config = {
							headers: {
								'reCAPTCHA-Token': token
							}
						};

						this.$http.get('/forms/subscriber/' + this.subscriberKey, config)
							.then((response) => {

								if (response.data.success) {

									this.subscriber = response.data.subscriber;

									this.status.isLoading = false;
									this.status.isSuccess = true;
								}
								else {
									
									this.status.isLoading = false;
									this.status.isSuccess = false;
									this.status.message = 'There was an issue loading the subscriptions.';
								}
								
							})
							.catch((error) => {

								this.status.isLoading = false;
								this.status.isSuccess = false;
								this.status.message = 'There was an issue loading the subscriptions.';

								this.$newRelicAgent.noticeError(error.response.data.message, {
									component: 'SubscriptionsManage',
									method: 'getSubscriptions',
									type: 'ServerException'
								});
							});
					});
			});
		},
		subscribeSubscription (name) {

			window.dataLayer.push({
				event: 'NewsletterSubscribe',
				sessionId: this.$crypto.SHA256(this.emailId).toString(this.$crypto.enc.Hex),
				rb_session_id: this.$crypto.SHA1(this.emailId).toString(this.$crypto.enc.Hex),
				subscriptions: name,
			});

			this.updateSubscriptions();
		},

		unsubscribeSubscription (name) {

			window.dataLayer.push({
				event: 'NewsletterUnsubscribe',
				sessionId: this.$crypto.SHA256(this.emailId).toString(this.$crypto.enc.Hex),
				rb_session_id: this.$crypto.SHA1(this.emailId).toString(this.$crypto.enc.Hex),
				subscriptions: name,
			});

			this.updateSubscriptions();
		},

		updateSubscriptions () {

			this.$captcha.ready(() => {
				this.$captcha.execute(this.$captcha.siteKey, {action: 'Subscriptions'})
					.then((token) => {

						let config = {
							headers: {
								'reCAPTCHA-Token': token
							}
						};

						let payload = {
							emailId: this.emailId,
							subscriptions: this.subscriber.subscriptions
						};

						this.$http.put('/forms/subscriber/' + this.subscriberKey, payload, config)
							.then((response) => {

								if (response.data.success) {

									this.status.isLoading = false;
									this.status.isSuccess = true;
									this.status.message = 'You have been successfully subscribed.';
								}
								else {

									this.status.isLoading = false;
									this.status.isSuccess = false;
									this.status.message = 'There was an issue processing your request.';
								}
							})
							.catch((error) => {

								this.status.isLoading = false;
								this.status.isSuccess = false;
								this.status.message = 'There was an issue processing your request.';

								this.$newRelicAgent.noticeError(error.response.data.message, {
									component: 'SubscriptionsManage',
									method: 'updateSubscriptions',
									type: 'ServerException'
								});
							});
					});
			});
		}
	}
}
</script>

<style lang="scss" scoped>
.subscriptions {

	td {

		height: 8rem;
	}

	.status {

		text-align: center;
		vertical-align: middle;
	}
}
</style>
