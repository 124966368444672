<template>

	<div class="subscriptions-unsubscribe">
		<div class="row">

			<div class="col-12 text-center">

				<a v-bind:href="logos[language].url">
					<img v-bind:src="logos[language].source" alt="International Fellowship of Christians and Jews" title="International Fellowship of Christians and Jews" class="logo img-fluid" />
				</a>

			</div>

		</div>

		<h1>Unsubscribe</h1>

		<hr />

		<p>We’re sad to see you go, but respect your communication preferences. Please click on the unsubscribe button below to stop all email communications (you will still receive transactional receipts).</p>

		<form id="subscriptions" novalidate v-bind:class="{'was-validated': status.isSubmitted}">

			<div class="alert text-center" v-if="status.isSubmitted && !status.isLoading && status.message != ''" v-bind:class="{'alert-success': status.isSubmitted && status.isSuccess && !status.isLoading, 'alert-danger': status.isSubmitted && !status.isSuccess && !status.isLoading}">
				{{status.message}}
			</div>

			<button type="button" class="btn btn-lg btn-primary" v-bind:disabled="status.isLoading" v-on:click="updateSubscriptions">
				Unsubscribe
				<span v-show="status.isLoading"><i class="fas fa-spinner fa-pulse ml-2"></i></span>
			</button>

		</form>

	</div>

</template>

<script>

import logos from '@/assets/data/logos.json';
import logosTest from '@/assets/data/logos-test.json';

export default {
	name: 'SubscriptionsUnsubscribe',
	data () {
		return {
			subscriberKey: this.$route.params.subscriberKey,
			logos: this.$route.query.version == 2 ? logosTest : logos,
			language: 'en',
			status: {
				isSubmitted: false,
				isLoading: false,
				isSuccess: true,
				message: ''
			}
		}
	},
	methods: {

		updateSubscriptions () {

			this.status.isSubmitted = true;
			this.status.isLoading = true;

			this.$captcha.ready(() => {
				this.$captcha.execute(this.$captcha.siteKey, {action: 'Subscriptions'})
					.then((token) => {

						let config = {
							headers: {
								'reCAPTCHA-Token': token
							}
						};

						let payload = {
							subscriptions: []
						};

						this.$http.put('/forms/subscriber/' + this.subscriberKey, payload, config)
							.then(() => {

								this.status.isLoading = false;
								this.status.isSuccess = true;
								this.status.message = 'You have been successfully unsubscribed.';

								window.dataLayer.push({
									event: 'NewsletterUnsubscribeAll',
									sessionId: this.$crypto.SHA256(this.emailId).toString(this.$crypto.enc.Hex),
									rb_session_id: this.$crypto.SHA1(this.emailId).toString(this.$crypto.enc.Hex)
								});

								
							})
							.catch((error) => {

								this.status.isLoading = false;
								this.status.isSuccess = false;
								this.status.message = 'There was an issue processing your request.';

								this.$newRelicAgent.noticeError(error.response.data.message, {
									component: 'SubscriptionsUnsubscribe',
									method: 'updateSubscriptions',
									type: 'ServerException'
								});
							});
					});
			});
		}
	}
}
</script>

<style lang="scss" scoped>
.subscriptions {

	.status {

		text-align: center;
		vertical-align: middle;
	}

	table {

		td {

			height: 10rem;
		}
	}
}
</style>
