<template>
	<div class="acknowledgment">

		<div class="row">

			<div class="col-12 text-center">

				<a v-bind:href="logos[acknowledgment.language].url">
					<img v-bind:src="logos[acknowledgment.language].source" alt="International Fellowship of Christians and Jews" title="International Fellowship of Christians and Jews" class="img-fluid" />
				</a><br /><br />
				<h1 class="title mb-4" v-html="acknowledgment.title"></h1>

			</div>

		</div>

		<div class="row" v-if="acknowledgment.layoutType == 1">

			<div class="col-12 text-center">

				<Media v-bind:media-type="acknowledgment.mediaType" v-bind:media="acknowledgment.media" />

			</div>

			<div class="col-12">

				<p><em>Shalom</em>,</p>

				<div v-if="acknowledgment.content == ''">

					<p>Thank you for your steadfast commitment to the ministry of blessing God’s people. May He bless you according to His promise in Genesis 12:3.</p>

				</div>

				<div v-else>

					<p v-html="acknowledgment.content"></p>

				</div>

			</div>

		</div>

		<div class="row" v-if="acknowledgment.layoutType == 2">

			<div class="col-sm-12 col-md-6">

				<Media v-bind:media-type="acknowledgment.mediaType" v-bind:media="acknowledgment.media" />

			</div>

			<div class="col-sm-12 col-md-6">

				<p><em>Shalom</em>,</p>

				<div v-if="acknowledgment.content == ''">

					<p>Thank you for your steadfast commitment to the ministry of blessing God’s people. May He bless you according to His promise in Genesis 12:3.</p>

				</div>

				<div v-else>

					<p v-html="acknowledgment.content"></p>

				</div>

			</div>

		</div>
		<div class="row" v-if="acknowledgment.hasDownload">
			
			<div class="container">
				<div class="row">
					<div class="col-12 text-center">
						<a class="btn btn-primary btn-lg" type="button" data-site-region="button" data-link-type="survey-download-button" v-bind:href="this.acknowledgment.downloadUrl">
						{{acknowledgment.buttonText}}
						</a>
					</div>
				</div>
			</div>

		</div>
		<hr>

		<div class="container">
			
			<div class="row fundraising-story">

				<div class="col-12 text-center">
					<h3>Learn More About The Fellowship's Impact</h3>
				</div>

				<div class="col-sm-12 col-md-4">
					<article class="card">
						<div class="card-header">
							<span class="card-category">Poverty</span>
							<a href="https://www.ifcj.org/who-we-help/poverty">
							<img src="https://media.ifcj.org/form-media/images/acnowledgement-14746553.jpg" data-site-region="thank-you-card" data-link-type="poverty-image" alt="Elderly woman brown sweater holding praying hands " class="img-fluid" loading="lazy" width="750" height="375">
							</a>
						</div>
						<div class="card-body">
							<h4>Blessing Vulnerable Jews</h4>
							<p>Learn how The Fellowship helps vulnerable Jewish people living in poverty in Israel and around the world by providing the essentials they need to survive.</p>
						</div>
						<div class="card-footer">
							<a href="https://www.ifcj.org/who-we-help/poverty" class="btn btn-solid-primary" data-site-region="thank-you-card" data-link-type="poverty-button">Learn More</a>
						</div>
					</article>
				</div>

				<div class="col-sm-12 col-md-4">
					<article class="card">
						<div class="card-header">
							<span class="card-category">Security</span>
							<a href="https://www.ifcj.org/who-we-help/security">
							<img src="https://media.ifcj.org/form-media/images/acnowledgement-14374317.jpg" data-site-region="thank-you-card" data-link-type="security-image" alt="Three young teenage girls watch bomb shelter being installed" class="img-fluid" loading="lazy" width="750" height="375">
							</a>
						</div>
						<div class="card-body">
							<h4>Protecting Israel and Her People</h4>
							<p>Read about victims of terrorism and war and learn how they found safety and security with bomb shelters and emergency aid provided by The Fellowship.</p>
						</div>
						<div class="card-footer">
							<a href="https://www.ifcj.org/who-we-help/security" class="btn btn-solid-primary" data-site-region="thank-you-card" data-link-type="security-button">Learn More</a>
						</div>
					</article>
				</div>

				<div class="col-sm-12 col-md-4">
					<article class="card">
						<div class="card-header">
							<span class="card-category">Aliyah</span>
							<a href="https://www.ifcj.org/who-we-help/aliyah">
							<img src="https://media.ifcj.org/form-media/images/acnowledgement-14735960.jpg" data-site-region="thank-you-card" data-link-type="aliyah-image" alt="Girl on tarmac with flags of Israel" class="img-fluid" loading="lazy" width="750" height="375">
							</a>
						</div>
						<div class="card-body">
							<h4>Returning to the Holy Land</h4>
							<p>Discover the stories of Jewish families from around the world who are returning to their homeland of Israel with the help of The Fellowship.</p>
						</div>
						<div class="card-footer">
							<a href="https://www.ifcj.org/who-we-help/aliyah" class="btn btn-solid-primary" data-site-region="thank-you-card" data-link-type="aliyah-button">Learn More</a>
						</div>
					</article>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
import Media from '@/components/Media.vue';

import logos from '@/assets/data/logos.json';

export default {
	name: 'SurveyAcknowledgment',
	components: {
		Media
	},
	data () {
		return {
			logos: logos,
			acknowledgment: {
				layoutType: 1,
				language: 'en',
				pageTitle: 'Thank You',
				title: 'Thank You',
				content: '',
				mediaType: 0,
				hasDownload: false,
				downloadUrl: '',
				buttonText: ''
			}
		}
	},
	mounted () {

		if (this.$route.params.primaryId != undefined) {

			this.getAcknowledgment(this.$route.params.primaryId, this.$route.params.secondaryId);
		}
	},
	methods: {

		getAcknowledgment (primaryId, secondaryId) {

			this.$http.get('/forms/survey/' + primaryId + '/' + secondaryId + '/acknowledgment')
				.then((response) => {

					this.acknowledgment = Object.assign(this.acknowledgment, response.data.acknowledgment);

					document.title = this.acknowledgment.pageTitle + ' | ' + document.title;
				})
				.catch ((error) => {

					this.$newRelicAgent.noticeError(error.response.data.message, {
						component: 'SurveyAcknowledgment',
						method: 'getAcknowledgment',
						type: 'ServerException'
					});
				});
		},

	}
}
</script>

<style lang="scss" scoped>

	hr {
		height: 1px;
		border: 0;
		background-color: rgba(0,91,158,.8);
	}

	.card-category {
		font-family: roboto,sans-serif;
		font-size: 1rem;
		font-weight: 500;
		margin-bottom: 0.5rem;
	}

</style>
