<template>
	<div class="modal" tabindex="-1" role="dialog" v-bind:class="{active: isActive}">
		<div class="modal-dialog" role="document">
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title">
						<slot name="title"></slot>
					</h5>
					<button type="button" class="close" aria-label="Close" v-on:click="$emit('hide')">
						<span aria-hidden="true"><i class="fas fa-times"></i></span>
					</button>
				</div>
				<div class="modal-body">
					<slot name="body"></slot>
				</div>
				<div class="modal-footer">
					<slot name="footer"></slot>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'Modal',
	props: {
		isActive: {
			type: Boolean,
			default: false
		}
	}
}
</script>

<style lang="scss" scoped>
.modal {

	display: flex;
	justify-content: center;
	align-items: center;
	visibility: hidden;
	opacity: 0;
	transition: all .15s ease-in-out;

	&.active {

		visibility: visible;
		opacity: 1;
		background-color: rgba(0, 0, 0, .75);
	}

	.modal-dialog {

		margin: auto;

		.modal-content {

			max-height: 100%;

			.modal-body {

				overflow: auto;
			}

			.modal-footer {

				display: block;
			}
		}
	}
}
</style>
