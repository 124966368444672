<template>
	<div class="acknowledgment">

		<div class="row">

			<div class="col-12 text-center">

				<a v-bind:href="logos[acknowledgment.language].url">
					<img v-bind:src="logos[acknowledgment.language].source" alt="International Fellowship of Christians and Jews" title="International Fellowship of Christians and Jews" class="img-fluid" />
				</a><br /><br />

				<h1 class="title mb-4" v-html="acknowledgment.title"></h1>

			</div>

		</div>

		<div class="row">

			<div class="col-12">

				<div class="content">

                    <p>Your time is valuable, so we appreciate you taking a moment to give us your feedback. Thank you for telling us about your experience as a monthly giver. You're building a better Fellowship — which means we can help more Holocaust survivors, impoverished Jews, and vulnerable families.</p>

                    <p>If you’d like to chat more about the reasons you stopped your monthly gift, please call us <b>1-800-486-8844</b>. If you would like us to pray for you, simply reply to this email with your <a href="mailto:info@ifcj.org?subject=My Prayer Request">prayer request</a>. It would be our honor to bring your concerns to the Lord.</p>

                    <p>Thank you again for everything you've done for the Jewish people in need around the world.</p>

                    <p>With prayers for <em>shalom</em>, peace,</p> 

                   <p> Your Donor Relations Team</p>
					
				</div>

			</div>

		</div>

	</div>
</template>

<script>
import logos from '@/assets/data/logos.json';

export default {
	name: 'SurveyFirmCancelAcknowledgment',
	data () {
		return {
			logos: logos,
			acknowledgment: {
				language: 'en',
				pageTitle: '',
				title: 'Thanks for Building a Better Fellowship',
			}
		}
	},
	created () {

		document.title;

	}
}
</script>

<style lang="scss" scoped>
</style>
