<template>

	<section class="donation-contact">

		<div class="form-row">

			<div class="form-group col-md-6">
				<label for="firstName">{{fieldNames[language].firstName}}*</label>
				<input id="firstName" type="text" autocomplete="given-name" maxlength="20" class="form-control" required v-model="contact.firstName" v-on:input="validation.contact.firstName.$touch" v-bind:class="{'is-invalid': validation.contact.firstName.$invalid && validation.contact.firstName.$dirty, 'is-valid': !validation.contact.firstName.$invalid && validation.contact.firstName.$dirty}">
				<div class="invalid-feedback">
					<div v-if="validation.contact.firstName.$invalid && validation.contact.firstName.$dirty || isSubmitted">
					{{formFieldErrors[language].firstName}}
					</div>
				</div>
			</div>

			<div class="form-group col-md-6">
				<label for="lastName">{{fieldNames[language].lastName}}*</label>
				<input id="lastName" type="text" autocomplete="family-name" maxlength="20" class="form-control" required v-model="contact.lastName" v-on:input="validation.contact.lastName.$touch" v-bind:class="{'is-invalid': validation.contact.lastName.$invalid && validation.contact.lastName.$dirty, 'is-valid': !validation.contact.lastName.$invalid && validation.contact.lastName.$dirty}">
				<div class="invalid-feedback">
					<div v-if="validation.contact.lastName.$invalid && validation.contact.lastName.$dirty || isSubmitted">
					{{formFieldErrors[language].lastName}}
					</div>
				</div>
			</div>

		</div>

		<div class="form-group">
			<label for="streetAddress">{{fieldNames[language].streetAddress}}*</label>
			<input id="streetAddress" autocomplete="street-address" maxlength="35" type="text" class="form-control" required v-model="contact.streetAddress" v-on:input="validation.contact.streetAddress.$touch" v-bind:class="{'is-invalid': validation.contact.streetAddress.$invalid && validation.contact.streetAddress.$dirty, 'is-valid': !validation.contact.streetAddress.$invalid && validation.contact.streetAddress.$dirty}">
			<div class="invalid-feedback">
				<div v-if="validation.contact.streetAddress.$invalid && validation.contact.streetAddress.$dirty || isSubmitted">
					{{formFieldErrors[language].streetAddress}}
				</div>
			</div>
		</div>

		<div class="form-row">

			<div class="form-group col-md-6">
				<label for="city">{{fieldNames[language].city}}*</label>
				<input id="city" type="text" autocomplete="address-level2" maxlength="30" class="form-control" required v-model="contact.city" v-on:input="validation.contact.city.$touch" v-bind:class="{'is-invalid': validation.contact.city.$invalid && validation.contact.city.$dirty, 'is-valid': !validation.contact.city.$invalid && validation.contact.city.$dirty}">
				<div class="invalid-feedback">
					<div v-if="validation.contact.city.$invalid && validation.contact.city.$dirty || isSubmitted">
						{{formFieldErrors[language].city}}
					</div>
				</div>
			</div>

			<div class="form-group col-md-6">
				<label for="state">{{fieldNames[language].state}}*</label>
				<input id="state" type="text" autocomplete="address-level1" maxlength="50" class="form-control" required v-model="contact.state" v-if="contact.country != 'US'" v-on:input="validation.contact.state.$touch" v-bind:class="{'is-invalid': validation.contact.state.$invalid && validation.contact.state.$dirty, 'is-valid': !validation.contact.state.$invalid && validation.contact.state.$dirty}">
				<select id="state" autocomplete="state" class="custom-select" required v-model="contact.state" v-if="contact.country == 'US'">
					<option v-for="(usstate) in usStates" v-bind:key="usstate.code" v-bind:value="usstate.code">{{usstate.name}}</option>
				</select>
				<div class="invalid-feedback">
					<div v-if="validation.contact.state.$invalid && validation.contact.state.$dirty || isSubmitted">
						{{formFieldErrors[language].state}}
					</div>
				</div>
			</div>

		</div>

		<div class="form-row">
			<div class="form-group col-md-6">
				<label for="postalCode">{{fieldNames[language].postalCode}}*</label>
				<input id="postalCode" type="text" autocomplete="postal-code" maxlength="16" class="form-control" required v-model="contact.postalCode" v-on:input="validation.contact.postalCode.$touch" v-bind:class="{'is-invalid': validation.contact.postalCode.$invalid && validation.contact.postalCode.$dirty, 'is-valid': !validation.contact.postalCode.$invalid && validation.contact.postalCode.$dirty}">
				<div class="invalid-feedback">
					<div v-if="validation.contact.postalCode.$invalid && validation.contact.postalCode.$dirty || isSubmitted">
						{{formFieldErrors[language].postalCode}}
					</div>
				</div>
			</div>
			<div class="form-group col-md-6">
				<label for="country">{{fieldNames[language].country}}*</label>
					<select id="country" autocomplete="country" class="custom-select" required v-model="contact.country" v-on:change="modal.canadianDonors.status.isActive = true && contact.country == 'CA'">
					<option v-for="(country) in countries" v-bind:key="country.code" v-bind:value="country.code">{{country.name}}</option>
				</select>
				<div class="invalid-feedback">
					<div v-if="validation.contact.country.$invalid && validation.contact.country.$dirty && validation.contact.country.$error || isSubmitted">
						{{formFieldErrors[language].country}}
					</div>
				</div>
			</div>
		</div>


		<div class="form-row">

			<div class="form-group col-md-6">
				<label for="phoneNumber">{{fieldNames[language].phoneNumber}}*</label>
				<input id="phoneNumber" type="tel" autocomplete="tel" maxlength="13" class="form-control" required v-model="contact.phoneNumber" v-on:input="validation.contact.phoneNumber.$touch" v-bind:class="{'is-invalid': validation.contact.phoneNumber.$invalid && validation.contact.phoneNumber.$dirty, 'is-valid': !validation.contact.phoneNumber.$invalid && validation.contact.phoneNumber.$dirty}">
				<div class="invalid-feedback">
					<div v-if="validation.contact.phoneNumber.$invalid && validation.contact.phoneNumber.$dirty || isSubmitted">
						{{formFieldErrors[language].phoneNumber}}
					</div>
				</div>
			</div>

			<div class="form-group col-md-6">
				<label for="emailAddress">{{fieldNames[language].emailAddress}}*</label>
				<input id="emailAddress" type="email" autocomplete="email" maxlength="50" class="form-control" required v-model="contact.emailAddress" v-on:input="validation.contact.emailAddress.$touch" v-bind:class="{'is-invalid': validation.contact.emailAddress.$invalid && validation.contact.emailAddress.$dirty, 'is-valid': !validation.contact.emailAddress.$invalid && validation.contact.emailAddress.$dirty}">

				<div class="invalid-feedback">
					<div v-if="validation.contact.emailAddress.$invalid && validation.contact.emailAddress.$dirty || isSubmitted">
						{{formFieldErrors[language].emailAddress}}
					</div>
				</div>
			</div>

		</div>

		<p class="text-center small" v-html="disclaimers[language].email"></p>

		<div class="input-option mb-3" v-if="hasSMSField" v-bind:class="{selected: contact.sms}" v-on:click="contact.sms = !contact.sms">

			<div class="input-button">

				<div class="option-checkbox">
					<div v-show="contact.sms"><i class="fas fa-check"></i></div>
				</div>

			</div>

			<div class="input-label">
				<p class="small" v-html="disclaimers[language].sms"></p>

			</div>

		</div>

		<div class="form-row" v-if="hasNoteField">

			<div class="form-group col-md-12">
				<label for="note">{{noteLabel}}*</label>
				<textarea id="note" class="form-control" v-model="note" v-on:input="updateNote"></textarea>
			</div>

		</div>

		<Modal v-bind:isActive="modal.canadianDonors.status.isActive" @hide="modal.canadianDonors.status.isActive = false">
			<template v-slot:title>
				Canadian Donors
			</template>
			<template v-slot:body>
				<p>If you wish to donate in CAD dollars and receive a Canadian tax receipt, please donate through our Canadian website by, clicking <a href="https://www.ifcj.ca/site/Donation2?df_id=1281&1281.donation=form1">here</a>.</p>
			</template>
			<template v-slot:footer>
				<div class="row no-gutters">
					<div class="col-3">
					</div>
					<div class="col-9 text-right">
						<button type="button" class="btn btn-link mr-1" data-dismiss="modal" v-on:click="modal.canadianDonors.status.isActive = false">Close</button>
						<a href="https://www.ifcj.ca/site/Donation2?df_id=1281&1281.donation=form1" class="btn btn-primary">Yes, Take Me There</a>
					</div>
				</div>
			</template>
		</Modal>

	</section>

</template>

<script>
import fieldNames from '@/assets/data/fieldnames.json';
import disclaimers from '@/assets/data/disclaimers.json';
import countries from '@/assets/data/countries.json';
import usStates from '@/assets/data/countries/united-states.json';
import formFieldErrors from '@/assets/data/formfielderrors.json';
import Modal from '@/components/Modal.vue';

export default {
	name: 'DonationContact',
	components: {
		Modal
	},
	props: {
		defaultCountry: {
			type: String
		},
		// defaultState: {
		// 	type: String
		// },
		hasNoteField: {
			type: Boolean,
			default: false
		},
		noteLabel: {
			type: String,
			default: ''
		},
		language: {
			type: String,
			default: 'en'
		},
		contact: {
			type: Object,
			required: true
		},
		validation: {
			type: Object
		},
		isSubmitted: {
			type: Boolean,
			default: false
		},
		hasSMSField: {
			type: Boolean,
			default: false
		}
	},
	data () {
		return {
			fieldNames: fieldNames,
			disclaimers: disclaimers,
			formFieldErrors: formFieldErrors,
			countries: countries,
			usStates: usStates,
			note: '',
			modal: {
				canadianDonors: {
					status: {
						isActive: false
					}
				}
			},
		}
	},

	created () {

		this.contact.country = this.defaultCountry;
		//this.contact.state = this.defaultState;
			
	},
	methods: {
		
		updateNote () {

			this.$emit('update:note', this.note);
		}

	}
}
</script>

<style lang="scss" scoped>
</style>