<template>

	<section class="donation-payment">

		<nav class="nav nav-pills nav-justified mb-3">
			<a class="nav-item nav-link payment-method" v-on:click="payment.method = 1" v-bind:class="{active: payment.method == 1}"><i class="fas fa-credit-card fa-lg mr-2"></i>{{headerNames[language].card}}</a>
			<a class="nav-item nav-link payment-method" v-on:click="payment.method = 2" v-bind:class="{active: payment.method == 2}"><i class="fas fa-university fa-lg mr-2"></i>{{headerNames[language].bank}}</a>
			<a class="nav-item nav-link payment-method" v-on:click="payment.method = 3" v-bind:class="{active: payment.method == 3}"><i class="fab fa-paypal fa-lg mr-2"></i>{{headerNames[language].paypal}}</a>
		</nav>

		<div class="payment-methods mb-3">
			<div class="drawer" v-bind:class="{active: payment.method == 1}">
				<div class="form-row">
					<div class="form-group col-md-6">
						<label for="cardfullName">{{fieldNames[language].cardName}}*</label>
						<input id="cardfullName" type="text" class="form-control" v-model="payment.details.creditCard.fullName" required  v-on:input="validation.payment.details.creditCard.fullName.$touch" v-bind:class="{'is-invalid': validation.payment.details.creditCard.fullName.$invalid && validation.payment.details.creditCard.fullName.$dirty, 'is-valid': !validation.payment.details.creditCard.fullName.$invalid && validation.payment.details.creditCard.fullName.$dirty}">
						<div class="invalid-feedback">
							<div v-if="validation.payment.details.creditCard.fullName.$invalid && validation.payment.details.creditCard.fullName.$dirty || isSubmitted">
								{{formFieldErrors[language].cardName}}
							</div>
						</div>
					</div>
					<div class="form-group col-md-6">
						<label for="cardNumber">{{fieldNames[language].cardNumber}}*</label>
						<input id="cardNumber" type="text" maxlength="19" class="form-control" v-model="payment.details.creditCard.number" required  v-on:input="validation.payment.details.creditCard.number.$touch" v-bind:class="{'is-invalid': validation.payment.details.creditCard.number.$invalid && validation.payment.details.creditCard.number.$dirty, 'is-valid': !validation.payment.details.creditCard.number.$invalid && validation.payment.details.creditCard.number.$dirty}">
						<div class="invalid-feedback">
							<div v-if="validation.payment.details.creditCard.number.$invalid && validation.payment.details.creditCard.number.$dirty || isSubmitted">
								{{formFieldErrors[language].cardNumber}}
							</div>
						</div>
					</div>
				</div>
				<div class="form-row">
					<div class="form-group col-md-4">
						<label for="cardExpireMonth">{{fieldNames[language].cardExpireMonth}}*</label>
						<select id="cardExpireMonth" class="custom-select" v-model="payment.details.creditCard.expireMonth" v-on:change="setValidExpiration" required v-bind:class="{'is-invalid': !isValidExpiration || validation.payment.details.creditCard.expireMonth.$invalid && validation.payment.details.creditCard.expireMonth.$dirty, 'is-valid': !validation.payment.details.creditCard.expireMonth.$invalid && validation.payment.details.creditCard.expireMonth.$dirty}">
							<option selected disabled></option>
							<option value="1">01 - January</option>
							<option value="2">02 - February</option>
							<option value="3">03 - March</option>
							<option value="4">04 - April</option>
							<option value="5">05 - May</option>
							<option value="6">06 - June</option>
							<option value="7">07 - July</option>
							<option value="8">08 - August</option>
							<option value="9">09 - September</option>
							<option value="10">10 - October</option>
							<option value="11">11 - November</option>
							<option value="12">12 - December</option>
						</select>
						<div class="invalid-feedback">
							<div v-if="validation.payment.details.creditCard.expireMonth.$invalid && validation.payment.details.creditCard.expireMonth.$dirty || isSubmitted">
								{{formFieldErrors[language].cardExpireMonth}}
							</div>
						</div>
					</div>

					<div class="form-group col-md-4">
						<label for="cardExpireYear">{{fieldNames[language].cardExpireYear}}*</label>
						<select id="cardExpireYear" class="custom-select" v-model="payment.details.creditCard.expireYear" v-on:change="setValidExpiration"  required v-bind:class="{'is-invalid': !isValidExpiration || validation.payment.details.creditCard.expireYear.$invalid && validation.payment.details.creditCard.expireYear.$dirty, 'is-valid': !validation.payment.details.creditCard.expireYear.$invalid && validation.payment.details.creditCard.expireYear.$dirty}">
							<option selected disabled></option>
							<option v-for="year in years" v-bind:key="year">{{year}}</option>
						</select>
						<div class="invalid-feedback">
							<div v-if="validation.payment.details.creditCard.expireYear.$invalid && validation.payment.details.creditCard.expireYear.$dirty || isSubmitted">
								{{formFieldErrors[language].cardExpireYear}}
							</div>
						</div>
					</div>
					<div class="form-group col-md-4">
						<label for="cardCVV">{{fieldNames[language].cardCVV}}*</label>
						<input id="cardCVV" type="text" class="form-control" v-model="payment.details.creditCard.cvv"  v-on:input="validation.payment.details.creditCard.cvv.$touch" required v-bind:class="{'is-invalid': validation.payment.details.creditCard.cvv.$invalid && validation.payment.details.creditCard.cvv.$dirty, 'is-valid': !validation.payment.details.creditCard.cvv.$invalid && validation.payment.details.creditCard.cvv.$dirty}">
						<div class="invalid-feedback">
							<div v-if="validation.payment.details.creditCard.cvv.$invalid && validation.payment.details.creditCard.cvv.$dirty || isSubmitted">
								{{formFieldErrors[language].cardCVV}}
							</div>
						</div>
					</div>
				</div>

				<div class="text-danger text-center small" v-show="!isValidExpiration">
					{{formFieldErrors[language].validExpiration}} 
				</div>

			</div>

			<div class="drawer" v-bind:class="{active: payment.method == 2}">
				<div class="form-row"> 
					<div class="form-group col-md-6">
						<label for="achAccountNumber">{{fieldNames[language].bankAccountNumber}}*</label>
						<input id="achAccountNumber" type="text" maxlength="17" class="form-control" v-model="payment.details.ach.accountNumber" v-on:input="validation.payment.details.ach.accountNumber.$touch" required v-bind:class="{'is-invalid': validation.payment.details.ach.accountNumber.$invalid && validation.payment.details.ach.accountNumber.$dirty, 'is-valid': !validation.payment.details.ach.accountNumber.$invalid && validation.payment.details.ach.accountNumber.$dirty}">
						<div class="invalid-feedback">
							<div v-if="validation.payment.details.ach.accountNumber.$invalid && validation.payment.details.ach.accountNumber.$dirty || isSubmitted">
								{{formFieldErrors[language].bankAccountNumber}} 
							</div>
						</div>
					</div>
					<div class="form-group col-md-6">
						<label for="achRoutingNumber">{{fieldNames[language].bankRoutingNumber}}*</label>
						<input id="achRoutingNumber" type="text" class="form-control" v-model="payment.details.ach.routingNumber" v-on:input="validation.payment.details.ach.routingNumber.$touch" required v-bind:class="{'is-invalid': validation.payment.details.ach.routingNumber.$invalid && validation.payment.details.ach.routingNumber.$dirty, 'is-valid': !validation.payment.details.ach.routingNumber.$invalid && validation.payment.details.ach.routingNumber.$dirty}">
						<div class="invalid-feedback">
							<div v-if="validation.payment.details.ach.routingNumber.$invalid && validation.payment.details.ach.routingNumber.$dirty || isSubmitted">
								{{formFieldErrors[language].bankRoutingNumber}} 
							</div>
						</div>
					</div>
				</div>
				<div class="form-row">
					<div class="form-group col-sm-12 col-md-12">
						<label for="achConfirmation">{{fieldNames[language].bankConfirmation}}*</label>
						<div class="custom-control custom-checkbox">
							<input id="achConfirmation" type="checkbox" class="custom-control-input" v-model="payment.details.ach.confirmation" v-bind:required="payment.method == 2" v-bind:class="{'is-invalid': payment.details.ach.confirmation === false && isSubmitted}">
							<label for="achConfirmation" class="custom-control-label small">
								{{disclaimers[language].bankDisclaimer}}
							</label>
						</div>
					</div>
				</div>

				<div class="text-danger text-center small" v-show="payment.details.ach.confirmation == false && isSubmitted">
					{{formFieldErrors[language].bankConfirmation}}
				</div>
			</div>

			<div class="drawer" v-bind:class="{active: payment.method == 3}">

				<div class="text-center mb-3">{{disclaimers[language].paypalDisclaimer}}</div>

			</div>

			<div class="input-option mb-3" v-if="hasCCprocessing"  v-bind:class="{selected: payment.details.feeConfirmation}" v-on:click="setCCFeeConfirmation()">

					<div class="input-button">

						<div class="option-checkbox">
							<div v-show="payment.details.feeConfirmation"><i class="fas fa-check"></i></div>
						</div>

					</div>

					<div class="input-label">
						<small>Yes, I want to help God’s people even more by increasing my gift to help offset costs associated with my donation.
						<span v-if="payment.details.feeConfirmation == true" v-bind:class="{select: payment.details.feeConfirmation}"> <br/> <b>My donation will now be ${{payment.details.totalAmount}} </b></span></small>

					</div>

				</div>


		</div>

	</section>

</template>

<script>
import fieldNames from '@/assets/data/fieldnames.json';
import headerNames from '@/assets/data/headernames.json';
import disclaimers from '@/assets/data/disclaimers.json';
import formFieldErrors from '@/assets/data/formfielderrors.json';

export default {
	name: 'DonationPayment',
	props: {
		payment: {
			type: Object,
			required: true
		},
		language: {
			type: String,
			default: 'en'
		},
		validation: {
			type: Object
		},
		isSubmitted: {
			type: Boolean,
			default: false
		},
		hasCCprocessing: {
			type: Boolean,
			default: false
		}
		
	},
	data () {
		return {
			isValidExpiration: true,
			fieldNames: fieldNames,
			headerNames: headerNames,
			disclaimers: disclaimers,
			formFieldErrors: formFieldErrors
		}
	},

	computed: {
		years () {

			const year = new Date().getFullYear();
			let years = [];

			for (let i = year; i < (year + 15); i++) {
				years.push(i);
			}

			return years;
		}
	},

	methods: {

		setValidExpiration () {

			const date = new Date();
			let month = Number(this.payment.details.creditCard.expireMonth) - 1;
			let year = Number(this.payment.details.creditCard.expireYear);

			if (year > date.getFullYear() || (month >= date.getMonth() && year == date.getFullYear())) {

				this.isValidExpiration = true;
			}
			else {

				this.isValidExpiration = false;
			}
		},

		setCCFeeConfirmation () {

			this.payment.details.feeConfirmation =! this.payment.details.feeConfirmation;
			
		}
		
	}
}
</script>

<style lang="scss" scoped>
nav {
	.payment-method {

		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		border-radius: 0;
		background-color: rgba(0, 0, 0, .05);
		color: inherit;
		transition: all .15s ease-in-out;

		&:not(:last-of-type) {

			margin-right: 1px;
		}

		&:hover {

			background-color: rgba(0, 0, 0, .1);
		}

		&.active {

			background-color:rgb(0, 91, 158);

			&:hover {

				color: #fff;
			}
		}
	}
}

.drawer {

	display: none;

	&.active {

		display: block;
	}
}
</style>
