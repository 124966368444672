<template>

	<div class="donation-generic">

		<section class="introduction" v-bind:class="{'crisis': donation.isCrisis}">

			<div class="row">

				<div class="col-12 text-center">

					<a v-bind:href="logos[donation.language].url">
						<img v-bind:src="logos[donation.language].source" alt="International Fellowship of Christians and Jews" title="International Fellowship of Christians and Jews" class="logo img-fluid" />
					</a>

					<h1 v-html="donation.title"></h1>

				</div>

			</div>

		</section>

		<section class="content">

			<div class="row justify-content-center" v-if="donation.content != '' && donation.mediaType == 0">

				<div class="col-md-12" v-html="donation.content"></div>

			</div>

			<div class="row" v-if="donation.content != '' && donation.mediaType != 0">

				<div class="col-md-6 mb-3" v-html="donation.content"></div>

				<div class="col-md-6 mb-3 order-first order-md-last ">

					<Media v-bind:media-type="donation.mediaType" v-bind:media="donation.media" />

				</div>

			</div>

			<div class="row justify-content-center" v-if="donation.content == '' && donation.mediaType != 0">

				<div class="col-md-12">

					<Media v-bind:media-type="donation.mediaType" v-bind:media="donation.media" />

				</div>

			</div>

		</section>

		<form class="donation" novalidate v-on:submit.prevent="postDonation" v-bind:class="{'was-validated': isSubmitted, 'crisis': donation.isCrisis}">

			<section class="amounts">

				<h2 class="text-center">{{headerNames[donation.language].make}}</h2>

				<hr />

				<div v-if="donation.amounts.monthly.length > 0 || donation.amounts.hasMonthlyCustomField">

					<h5>{{headerNames[donation.language].monthly}}</h5>

					<div class="amount-group">

						<div class="amount-item" v-for="(amount, index) in donation.amounts.monthly" v-bind:key="'m' + index" v-bind:class="{active: activeAmount == 'm' + index}" v-on:click="setGift(amount, 2, 'm' + index)">${{amount}}</div>
						<div class="amount-item" v-if="donation.amounts.hasMonthlyCustomField" v-bind:class="{active: activeAmount == 'mc'}">
							<input type="number" step=".01" placeholder="Amount" class="form-control text-center" v-bind:min="minDonation" v-bind:max="maxDonation" v-model.number="customAmount.monthly" />
						</div>

					</div>

					<p class="text-center"><small>{{disclaimers[donation.language].monthly}}</small></p>

				</div>

				<div v-if="donation.amounts.single.length > 0 || donation.amounts.hasSingleCustomField">

					<h5>{{headerNames[donation.language].single}}</h5>

					<div class="amount-group">

						<div class="amount-item" v-for="(amount, index) in donation.amounts.single" v-bind:key="'s' + index" v-bind:class="{active: activeAmount == 's' + index}" v-on:click="setGift(amount, 1, 's' + index)">${{amount}}</div>
						<div class="amount-item" v-if="donation.amounts.hasSingleCustomField" v-bind:class="{active: activeAmount == 'sc'}">
							<input type="number" step=".01" placeholder="Amount" class="form-control text-center" v-bind:min="minDonation" v-bind:max="maxDonation" v-model.number="customAmount.single" />
						</div>

					</div>

				</div>
				
				<div class="text-danger text-center small mt-3" v-show="isSubmitted && activeAmount == null">
					{{formFieldErrors[donation.language].giftAmount}} 
				</div>

				<div class="text-danger text-center small" v-show="isSubmitted && activeAmount != null && (payment.totalAmount < minDonation || payment.totalAmount > maxDonation)">
					{{formFieldErrors[donation.language].customGift}} ${{minDonation}} & ${{maxDonation}}.
				</div>

			</section>

			<section class="tribute" v-if="donation.hasTributeFields">

				<h2 class="text-center">Customize Your Card</h2>

				<hr />

				<DonationTribute v-bind:tribute="tribute" v-bind:validation="$v" v-bind:language="donation.language"  v-bind:is-submitted="isSubmitted" />

			</section>

			<section class="payment">

				<h2 class="text-center">{{headerNames[donation.language].billing}}</h2>

				<hr />

				<div v-if="this.$route.query.test == 1">
					<DonationPayment v-bind:payment="payment" v-bind:language="donation.language" v-bind:validation="$v" v-bind:hasCCprocessing="donation.hasCCprocessing" v-bind:is-submitted="isSubmitted" />
					
					<DonationContact v-bind:contact="contact" v-on:update:contact="contact = $event" v-bind:validation="$v" v-bind:hasSMSField="donation.hasSMSField" v-bind:language="donation.language" v-bind:default-state="contact.state" v-bind:default-country="contact.country" v-on:update:note="note = $event" v-bind:has-note-field="donation.hasNoteField" v-bind:note-label="donation.noteLabel" v-bind:is-submitted="isSubmitted" class="mb-3" />
				</div> 

				<div v-if="this.$route.query.test != 1">
					<DonationContact v-bind:contact="contact" v-on:update:contact="contact = $event" v-bind:validation="$v" v-bind:hasSMSField="donation.hasSMSField" v-bind:language="donation.language" v-bind:default-state="contact.state" v-bind:default-country="contact.country" v-on:update:note="note = $event" v-bind:has-note-field="donation.hasNoteField" v-bind:note-label="donation.noteLabel" v-bind:is-submitted="isSubmitted" class="mb-3" />
					
					<DonationPayment v-bind:payment="payment" v-bind:language="donation.language" v-bind:validation="$v" v-bind:hasCCprocessing="donation.hasCCprocessing" v-bind:is-submitted="isSubmitted" />
				</div>

			</section>

			<section class="submit">

				<div class="alert alert-danger text-center" v-if="isSubmitted && !isSuccess && donation.language == 'en'">
					
					<span v-html="message"></span>

				</div>

				<div class="alert alert-danger text-center" v-if="isSubmitted && !isSuccess && donation.language == 'es'">
					
					<span v-html="messageES"></span>

				</div>

				<div class="alert alert-danger text-center" v-if="isSubmitted && !isSuccess && donation.language == 'pt'">
					
					<span v-html="messagePT"></span>

				</div>

				<div class="form-row justify-content-center">

					<div class="col-xs-12 col-sm-4">

						<button type="submit" class="btn btn-primary btn-lg btn-block" v-bind:disabled="isProcessing">
							{{donation.buttonText}}
							<span v-show="isProcessing"><i class="fas fa-spinner fa-pulse ml-2"></i></span>
						</button>
					</div>
					<p class="text-center mt-3"><small>{{disclaimers[donation.language].project}}</small></p>

				</div>

			</section>

		</form>

	</div>

</template>

<script>
import DonationTribute from '@/views/partials/Forms/DonationTribute.vue';
import DonationContact from '@/views/partials/Forms/DonationContact.vue';
import DonationPayment from '@/views/partials/Forms/DonationPayment.vue';
import Media from '@/components/Media.vue';

import fieldNames from '@/assets/data/fieldnames.json';
import disclaimers from '@/assets/data/disclaimers.json';
import logos from '@/assets/data/logos.json';
import logosTest from '@/assets/data/logos-test.json';
import logosNascar from '@/assets/data/logos-nascar.json';
import logosHannity from '@/assets/data/logos-hannity.json';
import logosSirius from '@/assets/data/logos-sirius.json';
import logosWava from '@/assets/data/logos-wava.json';
import logosIheart from '@/assets/data/logos-iheart.json';
import logosMGallagher from '@/assets/data/logos-mike-gallagher.json';
import logosJPeterson from '@/assets/data/logos-jordanPeterson.json';
import logosMLevin from '@/assets/data/logos-markLevin.json';
import headerNames from '@/assets/data/headernames.json';
import formFieldErrors from '@/assets/data/formfielderrors.json';

import { minLength, numeric, required, requiredIf } from 'vuelidate/lib/validators';

export default {
	name: 'DonationGeneric',
	components: {
		Media,
		DonationContact,
		DonationPayment,
		DonationTribute
	},
	data () {
		return {
			headerNames: headerNames,
			fieldNames: fieldNames,
			disclaimers: disclaimers,
			formFieldErrors: formFieldErrors,
			logos: this.$route.query.version == 2 ? logosTest : this.$route.query.version == 3 ? logosNascar : this.$route.query.version == 4 ? logosHannity : this.$route.query.version == 5 ? logosSirius : this.$route.query.version == 6 ? logosWava : this.$route.query.version == 7 ? logosIheart : this.$route.query.version == 8 ? logosMGallagher : this.$route.query.version == 9 ? logosJPeterson : this.$route.query.version == 10 ? logosMLevin : logos,
			motivationCode: this.$application.motivationCode,
			emailId: this.$route.query.emid === undefined ? '' : this.$route.query.emid,
			minDonation: 5,
			maxDonation: 25000,
			customAmount: {
				single: null,
				monthly: null
			},
			activeAmount: null,
			donation: {
				language: 'en',
				amounts: {
					single: [],
					hasSingleCustomField: null,
					monthly: [],
					hasMonthlyCustomField: null
				},
				hasNoteField: false,
				hasSMSField: false,
				hasCCprocessing: false,
				noteLabel: '',
			},
			tribute: {
				senderName: '',
				honoreeName: '',
				tributeType: '',
				isCustomAddress: false,
				recipient: {
					firstName: '',
					lastName: '',
					streetAddress: '',
					city: '',
					state: '',
					country: '',
					postalCode: ''
				}
			},
			contact: {
				firstName: '',
				lastName: '',
				streetAddress: '',
				city: '',
				state: '',
				country: 'US',
				postalCode: '',
				emailAddress: '',
				phoneNumber: '',
				sms: false,
			},
			note: '',
			payment: {
				totalAmount: 0,
				frequency: 1,
				method: 1,
				details: {
					ccFee: 2,
					feeConfirmation: false,
					ccFeeAmount: 0,
					totalAmount: 0,

					creditCard: {
						fullName: '',
						number: '',
						expireMonth: null,
						expireYear: null,
						cvv: '',
						
					},
					ach: {
						name: '',
						accountNumber: '',
						routingNumber: '',
						confirmation: false
					},
					paypal: {
						returnUrl: window.location.origin + '/donate/' + this.$route.params.primaryId + '/' + this.$route.params.secondaryId + '/thankyou',
						cancelUrl: window.location.href
					}
				},
			},
			gifts: [],
			isProcessing: false,
			isSubmitted: false,
			isSuccess: false,
			message: '',
			messageES: ''
		}
	},
	validations () {
		
		return {
			contact: {
				firstName: {
					required
				},
				lastName: {
					required
				},
				emailAddress: {
					required
				},
				phoneNumber: {
					required,
					numeric,
					minLength: minLength(10)
				},
				streetAddress: {
					required
				},
				city: {
					required
				},
				state: {
					required
				},
				postalCode: {
					required
				},
				country: {
					required
				}
			},
			tribute: {
				tributeType: {
					required: requiredIf(() => {
						return this.donation.hasTributeFields
					})
				},
				senderName: {
					required: requiredIf(() => {
						return this.donation.hasTributeFields
					})
				},
				honoreeName: {
					required: requiredIf(() => {
						return this.donation.hasTributeFields
					})
				},
				recipient:{
					firstName: {
						required: requiredIf(() => {
							return this.donation.hasTributeFields && this.tribute.isCustomAddress
						})
					},
					lastName: {
						required: requiredIf(() => {
							return this.donation.hasTributeFields && this.tribute.isCustomAddress
						})
					},
					streetAddress: {
						required: requiredIf(() => {
							return this.donation.hasTributeFields && this.tribute.isCustomAddress
						})
					},
					city: {
						required: requiredIf(() => {
							return this.donation.hasTributeFields && this.tribute.isCustomAddress
						})
					},
					state: {
						required: requiredIf(() => {
							return this.donation.hasTributeFields && this.tribute.isCustomAddress
						})
					},
					country: {
						required: requiredIf(() => {
							return this.donation.hasTributeFields && this.tribute.isCustomAddress
						})
					},
					postalCode: {
						required: requiredIf(() => {
							return this.donation.hasTributeFields && this.tribute.isCustomAddress
						})
					}
				}
			},
			payment: {
				details: {
					creditCard: {
						fullName: {
							required: requiredIf(() => {
								return this.payment.method == 1
							})
						},
						number: {
							numeric,
							minLength: minLength(12),
							required: requiredIf(() => {
								return this.payment.method == 1
							})
						},
						expireMonth:{
							required: requiredIf(() => {
								return this.payment.method == 1
							})
						},
						expireYear: {
							required: requiredIf(() => {
								return this.payment.method == 1
							})
						},
						cvv: {
							required: requiredIf(() => {
								return this.payment.method == 1
							})
						}
					},
					ach: {

						accountNumber: {
							required: requiredIf(() => {
								return this.payment.method == 2
							})
						},
						routingNumber: {
							required: requiredIf(() => {
								return this.payment.method == 2
							})
						},
						confirmation: {
							required: requiredIf(() => {
								return this.payment.method == 2
							})
						}
					}
				}
			}
		}
	},
	mounted () {

		this.getDonation(this.$route.params.primaryId, this.$route.params.secondaryId);

	},
	watch: {
		'donation.hasSMSField': function () {

			if(this.donation.hasSMSField){
				
				this.contact.sms = true;
			}
		},

		'customAmount.single': function (amount) {

			this.setGift(amount, 1, 8);
		},
		'customAmount.monthly': function (amount) {

			this.setGift(amount, 2, 4);
		},

		'payment.details.feeConfirmation': function(){
			if(this.payment.details.feeConfirmation == true) {
				this.payment.totalAmount = this.payment.details.totalAmount;
				
				this.gifts = [
					{
						amount: Number(this.payment.totalAmount),
						projectCode: this.donation.projectCode
					}
				];
				
			}
			if(this.payment.details.feeConfirmation == false) {
				this.payment.totalAmount = this.payment.details.totalAmount - this.payment.details.ccFeeAmount;
					this.gifts = [
					{
						amount: Number(this.payment.totalAmount),
						projectCode: this.donation.projectCode
					}
				];
				
			}	

		}
		

	},
	methods: {

		getDonation (primaryId, secondaryId) {

			this.$http.get('/forms/donation/' + primaryId + '/' + secondaryId)
			
				.then((response) => {

					if (response.data.success) {

						this.isSuccess = true;

						this.donation = response.data.donation;

						document.title = this.donation.pageTitle + ' | ' + document.title;

						if (this.$route.query.amount !== undefined && this.$route.query.frequency !== undefined) {

							if (this.$route.query.frequency == 1) {

								this.customAmount.single = Number(this.$route.query.amount);
							}
							else if (this.$route.query.frequency == 2) {

								this.customAmount.monthly = Number(this.$route.query.amount);
							}
						}
					}
					else {

						this.isSuccess = false;
						this.message = 'We were unable to process this request. The form does not exist.';
						this.messageES = 'No pudimos procesar esta solicitud. El formulario no existe.';
						this.messagePT = 'Não foi possível processar esta solicitação. O formulário não existe.';

						this.$newRelicAgent.noticeError(response.data.message,{
							component: 'DonationGeneric',
							method: 'getDonation',
							type: 'UserException'
						});
					}
				})
				.catch ((error) => {

					this.isSuccess = false;
					this.message = 'There was an issue processing your request. Please contact Donor Support at (800) 486-8844.';
					this.messageES = 'Hubo un problema procesando tu petición. Comuníquese con Asistencia a donantes al (800) 486-8844.';
					this.messagePT = 'Houve um problema ao processar seu pedido. Entre em contato com o Suporte aos Doadores pelo telefone (800) 486-8844.';

					this.$newRelicAgent.noticeError(error.response.data.message,{
						component: 'DonationGeneric',
						method: 'getDonation',
						type: 'ServerException'
					});
				});
		},

		getPaymentDetails (paymentMethod) {

			if (paymentMethod == 1) {
				
				return this.payment.details.creditCard;
			}
			else if (paymentMethod == 2) {

				return this.payment.details.ach;
			}
			else if (paymentMethod == 3) {

				return this.payment.details.paypal;
			}
		},


		getTribute () {

			if (this.donation.hasTributeFields) {

				if (!this.tribute.isCustomAddress) {
					
					this.tribute.recipient = this.contact;
				}
				
				return this.tribute;
			}
			else {

				return {};
			}

		},

		setGift (amount, frequency, activeAmount) {

			this.payment.frequency = frequency;
			this.activeAmount = activeAmount;
			this.payment.totalAmount = Number(amount);

			this.gifts = [
				{
					amount: Number(amount),
					projectCode: this.donation.projectCode
				}
			];


			this.setDonationProcessing(Number(amount));

			
		},

		setDonationProcessing (giftAmount) {

			let fee = this.payment.details.ccFee;
			let gift = giftAmount;
			this.payment.details.ccFeeAmount = ((gift * fee)/100).toFixed(2);
			this.payment.details.totalAmount = (gift + Number(this.payment.details.ccFeeAmount)).toFixed(2);

		},

		setAcknowledgment (donation) {

			let acknowledgment = {
				donationKey: donation.donationKey,
				projectCode: this.donation.projectCode,
				motivationCode: this.motivationCode,
				isCrisis: this.donation.isCrisis, 
				payment: {
					totalAmount: this.payment.totalAmount,
					frequency: this.payment.frequency
				},
				upsell: this.donation.upsell
			};

			window.sessionStorage.setItem('acknowledgment', JSON.stringify(acknowledgment));
		},

		postGtmEvent (donationKey) {
		
			let analytics_dataLayer = {
				sessionId: this.$crypto.SHA256(this.contact.emailAddress).toString(this.$crypto.enc.Hex),
				rb_session_id: this.$crypto.SHA1(this.contact.emailAddress).toString(this.$crypto.enc.Hex),
				fullEmailAddress: this.contact.emailAddress,
				motivationCode: this.motivationCode,
				smsOptIn: this.contact.sms,
				paymentMethod: this.payment.method,
				ecommerce: {
					transaction_id: donationKey,
					value: this.payment.totalAmount,
					currency: "USD",
					items: [{
						item_id: this.donation.primaryId + '.' + this.donation.secondaryId,
						item_name: 'Generic Donation Form',
						item_category: this.donation.projectCode,
						item_variant: this.payment.frequency,
						price: this.gifts[0].amount,
						quantity: 1
					}]  
				},
			};

			window.sessionStorage.setItem('analytics_dataLayer', JSON.stringify(analytics_dataLayer));

		},

		postDonation () {

			this.isSubmitted = true;
			this.isProcessing = true;

			if (this.$v.$invalid ||
				this.payment.frequency == null || this.gifts.length == 0 || this.payment.totalAmount < this.minDonation || this.payment.totalAmount > this.maxDonation ||
				(this.payment.method == 2 && this.payment.details.ach.confirmation == false)) {

				this.message = 'The form could not be submitted. Please review the fields above and resubmit.';
				this.messageES ='No se pudo enviar el formulario. Revise los campos anteriores y vuelva a enviarlos.';
				this.messagePT = 'Não se pode enviar o formulário, revisar os campos anteriores e voltar a enviá-los';

				this.isProcessing = false;
				this.isSuccess = false;

				return false;
			}

			this.$captcha.ready(() => {
				this.$captcha.execute(this.$captcha.siteKey, {action: 'GenericDonation'})
					.then((token) => {

						let config = {
							headers: {
								'reCAPTCHA-Token': token
							}
						};

						let payload = {
							formId: this.donation.formId,
							primaryId: this.donation.primaryId,
							secondaryId: this.donation.secondaryId,
							motivationCode: this.motivationCode,
							emailId: this.emailId,
							contact: this.contact,
							note: this.note,
							tribute: this.getTribute(),
							payment: {
								frequency: this.payment.frequency,
								method: this.payment.method,
								details: this.getPaymentDetails(this.payment.method)
							},
							gifts: this.gifts
						};

						this.$http.post('/forms/donation', payload, config)
							.then((response) => {

								if (response.data.success) {

									this.isSuccess = true;

									this.setAcknowledgment(response.data);

									this.postGtmEvent(response.data.donationKey);

										if (this.payment.method == 1) {

											window.location.href = this.donation.redirectUrl;

										}
										else if (this.payment.method == 2) {

											window.location.href = this.donation.redirectUrl;
										}
										else if (this.payment.method == 3) {

											window.location.href = response.data.redirectUrl;
										}

								}
								else {

									this.isProcessing = false;
									this.isSuccess = false;
									this.message = 'There was an issue processing your donation. Please contact Donor Support at (800) 486-8844. <br>' + response.data.message;
									this.messageES = 'Hubo un problema procesando tu petición. Comuníquese con Asistencia a donantes al (800) 486-8844. <br>' + response.data.message;
									this.messagePT = 'Houve um problema ao processar seu pedido. Entre em contato com o Suporte aos Doadores pelo telefone (800) 486-8844. <br>' + response.data.message;

									this.$newRelicAgent.noticeError(response.data.message, {
										component: 'DonationGeneric',
										method: 'postDonation',
										type: 'UserException'
									});
								}
							})
							.catch((error) => {

								this.isProcessing = false;
								this.isSuccess = false;
								this.message = 'There was an issue processing your request. Please contact Donor Support at (800) 486-8844.';
								this.messageES = 'Hubo un problema procesando tu petición. Comuníquese con Asistencia a donantes al (800) 486-8844.';
								this.messagePT = 'Houve um problema ao processar seu pedido. Entre em contato com o Suporte aos Doadores pelo telefone (800) 486-8844.';

								this.$newRelicAgent.noticeError(error.response.data.message, {
									component: 'DonationGeneric',
									method: 'postDonation',
									type: 'ServerException'
								});
							});
					});
			});
		}
	}
}
</script>


<style lang="scss" scoped>
.introduction, .content, .tribute, .amounts, .billing {

	margin-bottom: 2rem;
}

.crisis {

	h1, h2 {

		color: #E31D3C;
	}

	.amounts {

		h5 {

			background-color: #E31D3C;
		}
	}

	.btn {

		&.btn-primary {

			background-color: #E31D3C;
			border-color: #E31D3C;
		}
	}
}

.amounts {

	h5 {

		background-color: rgba(0, 91, 158);
		padding: 1rem;
		border-radius: 5px;
		color: #fff;
	}

	.amount-group {

		display: flex;
		align-items: stretch;
		justify-content: space-around;
		margin-bottom: 1rem;
		font-size: 1.5rem;
		font-weight: 700;

		.amount-item {

			display: flex;
			align-items: center;
			justify-content: center;
			width: 100%;
			background-color: rgba(0, 0, 0, .05);
			text-align: center;
			margin-right: 1px;
			padding: .5rem 1rem;
			transition: all .15s ease-in-out;

			&:hover {

				background-color: rgba(0, 0, 0, .1);
				cursor: pointer;
			}

			&.active {

				background-color: rgba(251, 239, 152, .5);
			}
		}
	}
}



@media (max-width: 575.98px) {

	.amounts {

		.amount-group {

			flex-wrap: wrap;

			.amount-item {

				margin-bottom: 1px;
			}
		}
	}
}
</style>