<template>

	<div class="subscriptions-signup">
		<div class="row">

			<div class="col-12 text-center">

				<a v-bind:href="logos[language].url">
					<img v-bind:src="logos[language].source" alt="International Fellowship of Christians and Jews" title="International Fellowship of Christians and Jews" class="logo img-fluid" />
				</a>

			</div>

		</div>

		<h1>Join The Fellowship Family</h1>

		<hr />

		<p>Join our faith community and receive emails that inspire your soul and show your holy impact. At any time, you can adjust your subscriptions. <strong>Please click on the emails you’re interested in and then click the “Signup” button. Welcome to the family!</strong></p>

		<form id="subscriptions" novalidate v-bind:class="{'was-validated': status.isSubmitted}">

			<div>

				<div class="form-row">

					<div class="form-group col-sm-12 col-md-6">
						<label for="firstName">First Name</label>
						<input type="text" class="form-control" id="firstName" maxlength="50" placeholder="" required v-model="form.firstName">
						<div class="invalid-feedback">
							Please enter your first name.
						</div>
					</div>

					<div class="form-group col-sm-12 col-md-6">
						<label for="lastName">Last Name</label>
						<input type="text" class="form-control" id="lastName" maxlength="50" placeholder="" required v-model="form.lastName">
						<div class="invalid-feedback">
							Please enter your last name.
						</div>
					</div>

				</div>

				<div class="form-row">
					<div class="form-group col-sm-12 col-md-12">
						<label for="emailAddress">Email Address</label>
						<input type="email" class="form-control" id="emailAddress" maxlength="50" placeholder="" required v-model="form.emailAddress">
						<div class="invalid-feedback">
							Please provide a valid email address.
						</div>
					</div>
				</div>

			</div>

			<table class="table subscriptions">

				<thead class="thead-light">

					<tr>

						<th>Subscription</th>
						<th class="text-center">Status</th>

					</tr>

				</thead>

				<tbody>

					<tr v-if="subscriber.subscriptions.length === 0">

						<td colspan="2" class="text-center" v-show="status.isLoading">

							<i class="fas fa-spinner fa-pulse fa-2x"></i><br />
							<small>Loading Subscriptions</small>

						</td>

						<td colspan="2" class="text-center" v-show="!status.isSuccess">

							<i class="fas fa-exclamation-triangle fa-2x"></i><br />
							<small>Error Loading Data</small>

						</td>

						<td colspan="2" class="text-center" v-show="!status.isLoading && status.isSuccess">There are no subscriptions to display.</td>

					</tr>

					<tr v-for="(subscription, index) in subscriber.subscriptions" v-bind:key="index">

						<td>
							<strong>{{subscription.name}}</strong><br />
							{{subscription.description}}
						</td>
						<td class="status">
							<button type="button" class="btn btn-link" v-if="subscription.status === true" v-on:click="subscription.status = false">Unsubscribe</button>
							<button type="button" class="btn btn-primary" v-if="subscription.status === false" v-on:click="subscription.status = true">Subscribe</button>
						</td>

					</tr>

				</tbody>

			</table>

			<div class="alert text-center" v-if="status.isSubmitted && !status.isLoading && status.message != ''" v-bind:class="{'alert-success': status.isSubmitted && status.isSuccess && !status.isLoading, 'alert-danger': status.isSubmitted && !status.isSuccess && !status.isLoading}">
				{{status.message}}
			</div>

			<div class="text-center">
				<button type="button" class="btn btn-lg btn-primary" v-bind:disabled="status.isLoading" v-on:click="createSubscriptions">
					Signup
					<span v-show="status.isLoading"><i class="fas fa-spinner fa-pulse ml-2"></i></span>
				</button>
			</div>

		</form>

	</div>

</template>

<script>
import logos from '@/assets/data/logos.json';
import logosTest from '@/assets/data/logos-test.json';

export default {
	name: 'SubscriptionsSignup',
	data () {
		return {
			subscriberKey: '00000000-0000-0000-0000-000000000000',
			emailId: this.$route.query.emailId == undefined ? '' : this.$route.query.emailId == undefined,
			subscriber: {
				subscriptions: []
			},
			motivationCode: this.$application.motivationCode,
			logos: this.$route.query.version == 2 ? logosTest : logos,
			language: 'en',
			form: {
				firstName: '',
				lastName: '',
				emailAddress: ''
			},
			status: {
				isSubmitted: false,
				isLoading: false,
				isSuccess: true,
				message: ''
			}
		}
	},
	created () {

		this.getSubscriptions();
	},
	methods: {

		getSubscriptions () {

			this.status.isLoading = true;

			this.$captcha.ready(() => {
				this.$captcha.execute(this.$captcha.siteKey, {action: 'Subscriptions'})
					.then((token) => {

						let config = {
							headers: {
								'reCAPTCHA-Token': token
							}
						};

						this.$http.get('/forms/subscriber/' + this.subscriberKey, config)
							.then((response) => {

								if (response.data.success) {

									this.subscriber = response.data.subscriber;

									this.status.isLoading = false;
									this.status.isSuccess = true;
								}
								else {
									
									this.status.isLoading = false;
									this.status.isSuccess = false;
									this.status.message = 'There was an issue loading the subscriptions.';
								}
								
							})
							.catch((error) => {

								this.status.isLoading = false;
								this.status.isSuccess = false;
								this.status.message = 'There was an issue loading the subscriptions.';

								this.$newRelicAgent.noticeError(error.response.data.message, {
									component: 'SubscriptionsSignup',
									method: 'getSubscriptions',
									type: 'ServerException'
								});
							});
					});
			});
		},

		createSubscriptions () {

			this.status.isSubmitted = true;
			this.status.isLoading = true;

			if (this.form.firstName == '' || this.form.lastName == '' || this.form.emailAddress == '') {

				this.status.message = 'The form could not be submitted. Please review the fields above and resubmit.';

				this.status.isLoading = false;
				this.status.isSuccess = false;

				return false;
			}

			this.$captcha.ready(() => {
				this.$captcha.execute(this.$captcha.siteKey, {action: 'Subscriptions'})
					.then((token) => {

						let config = {
							headers: {
								'reCAPTCHA-Token': token
							}
						};

						let payload = {
							subscriberKey: this.subscriberKey,
							emailId: this.emailId,
							firstName: this.form.firstName,
							lastName: this.form.lastName,
							emailAddress: this.form.emailAddress,
							motivationCode: this.motivationCode,
							subscriptions: this.subscriber.subscriptions,
						};

						this.$http.post('/forms/subscriber', payload, config)
							.then(() => {

								this.status.isLoading = false;
								this.status.isSuccess = true;
								this.status.message = 'You have been successfully subscribed.';

								
					
								var subscriptionsName = [];

								for (let i = 0; i < this.subscriber.subscriptions.length; i++) {
									if (this.subscriber.subscriptions[i].status == true) {

										subscriptionsName.push(this.subscriber.subscriptions[i].name);
									}

								}
								
								window.dataLayer.push({
									event: 'NewsletterSubscribe',
									sessionId: this.$crypto.SHA256(this.emailId).toString(this.$crypto.enc.Hex),
									rb_session_id: this.$crypto.SHA1(this.emailId).toString(this.$crypto.enc.Hex),
									fullEmailAddress: this.form.emailAddress,
									subscriptions: subscriptionsName,
								});

								
							})
							.catch((error) => {

								this.status.isLoading = false;
								this.status.isSuccess = false;
								this.status.message = 'There was an issue processing your request.';

								this.$newRelicAgent.noticeError(error.response.data.message, {
									component: 'SubscriptionsSignup',
									method: 'createSubscriptions',
									type: 'ServerException'
								});
							});

					});
			});
		}
	}
}
</script>

<style lang="scss" scoped>
.subscriptions {

	td {

		height: 8rem;
	}

	.status {

		text-align: center;
		vertical-align: middle;
	}
}
</style>
