import Home from '@/views/pages/Home.vue';
import DonationAcknowledgment from '@/views/pages/DonationAcknowledgment.vue';
import DonationGeneric from '@/views/pages/DonationGeneric.vue';
import DonationMain from '@/views/pages/DonationMain.vue';
import DonationUpgrade from '@/views/pages/DonationUpgrade.vue';
import SubscriptionsSignup from '@/views/pages/SubscriptionsSignup.vue';
import SubscriptionsManage from '@/views/pages/SubscriptionsManage.vue';
import SubscriptionsUnsubscribe from '@/views/pages/SubscriptionsUnsubscribe.vue';
import SurveyAcknowledgment from '@/views/pages/SurveyAcknowledgment.vue';
import SurveyGeneric from '@/views/pages/SurveyGeneric.vue';
import UpgradeAcknowledgment from '@/views/pages/UpgradeAcknowledgment.vue';
import Maintenance from '@/views/pages/Maintenance.vue';
import SurveyFirmCancel from '@/views/pages/SurveyFirmCancel.vue';
import SurveyFirmCancelAcknowledgment from '@/views/pages/SurveyFirmCancelAcknowledgment.vue'

const Routes = [
	{
		path: '/',
		component: Home,
		meta: {
			title: 'Web Forms'
		}
	},
	{
		path: '/maintenance',
		component: Maintenance,
		meta: {
			title: 'Maintenance'
		}
	},
	{
		path: '/donate',
		component: DonationMain,
		meta: {
			title: 'Donate'
		}
	},
	{
		path: '/donate/:primaryId/:secondaryId',
		component: DonationGeneric,
		meta: {
			title: 'Donate'
		}
	},
	{
		path: '/donate/thankyou',
		component: DonationAcknowledgment,
		meta: {
			title: 'Donate'
		}
	},
	{
		path: '/donate/:primaryId/:secondaryId/thankyou',
		component: DonationAcknowledgment,
		meta: {
			title: 'Donate'
		}
	},
	{
		path: '/subscriptions',
		component: SubscriptionsSignup,
		meta: {
			title: 'Join The Fellowship Family'
		}
	},
	{
		path: '/subscriptions/:subscriberKey',
		component: SubscriptionsManage,
		meta: {
			title: 'My Subscriptions'
		}
	},
	{
		path: '/subscriptions/unsubscribe/:subscriberKey',
		component: SubscriptionsUnsubscribe,
		meta: {
			title: 'Unsubscribe'
		}
	},
	{
		path: '/survey/:primaryId/:secondaryId',
		component: SurveyGeneric,
		meta: {
			title: 'Survey'
		}
	},
	{
		path: '/survey/thankyou',
		component: SurveyAcknowledgment,
		meta: {
			title: 'Survey'
		}
	},
	{
		path: '/survey/:primaryId/:secondaryId/thankyou',
		component: SurveyAcknowledgment,
		meta: {
			title: 'Survey'
		}
	},
	{
		path: '/upgrade/:entityId/:transactionId',
		component: DonationUpgrade,
		meta: {
			title: 'Donate'
		}
	},
	{
		path: '/upgrade/thankyou',
		component: UpgradeAcknowledgment,
		meta: {
			title: 'Donate'
		}
	},
	{
		path: '/firm-cancel/:entityId/:transactionId/:subscriberKey',
		component: SurveyFirmCancel,
		meta: {
			title: 'Survey'
		}
	},
	{
		path: '/firm-cancel/thankyou',
		component: SurveyFirmCancelAcknowledgment,
		meta: {
			title: 'Thank you'
		}
	},
	
];

export {
	Routes
};
