<template>

	<div class="acknowledgment">

		<div class="row">

			<div class="col-12 text-center">

				<a v-bind:href="logos[acknowledgment.language].url">
					<img v-bind:src="logos[acknowledgment.language].source" alt="International Fellowship of Christians and Jews" title="International Fellowship of Christians and Jews" class="logo" />
				</a>

			</div>

		</div>

		<div class="row" >

			<div class="col-12 text-center mb-4">

				<div v-if="acknowledgment.media && acknowledgment.media !== ''">
					<img v-bind:src="acknowledgment.media" alt="International Fellowship of Christians and Jews" title="International Fellowship of Christians and Jews"  />
				</div>
				<div v-else>
					<img src="/static/images/acknowledgment/thank-you.jpg" alt="Thank You" class="img-fluid" v-if="acknowledgment.language == 'en' && acknowledgment.isCrisis == false" />
					<img src="/static/images/acknowledgment/thank-you-es.jpg" alt="Gracias" class="img-fluid" v-if="acknowledgment.language == 'es' && acknowledgment.isCrisis == false" />
					<img src="/static/images/acknowledgment/obrigado.jpg" alt="Obrigado" class="img-fluid" v-if="acknowledgment.language == 'pt' && acknowledgment.isCrisis == false" />
					<img src="/static/images/acknowledgment/thank-you-crisis.jpg" alt="Obrigado" class="img-fluid" v-if="acknowledgment.language == 'en' && acknowledgment.isCrisis == true" />
				</div>

			</div>

		</div>

		<div class="row">

			<div class="col-12">

				<div v-if="modal.upsell.status.isSubmitted && modal.upsell.status.isSuccess" class="alert alert-primary">

					<p><strong>Thank you for your additional gift!</strong></p>

					<p>Your monthly gift of ${{form.upsell.amount}} has been processed and will help continue to support our lifesaving work and fulfill Biblical prophecy month after month.</p>

					<p>Below you will find the details of your original gift today.</p>

				</div>

				<p><em>Shalom</em>,</p>

				<div v-if="acknowledgment.content == ''">

					<p><em>Todah Rabah</em> &ndash; Thank You &mdash; for making a generous gift in support of The Fellowship&rsquo;s lifesaving ministries!</p>

					<p>The depth of your compassion for the Jewish people inspires us every day. Thank you for being a true blessing to the Jewish people and to The Fellowship.</p>

					<p><a href="https://help.ifcj.org/subscriptions">Click here to subscribe to our newsletter and stay informed about issues affecting Israel, the Jewish people, Jewish-Christian relations, and more</a>.</p>
					
					<p>Because of you&hellip;<br>Eyes will brighten<br>Tears will flow<br>Hearts will be lifted.</p>
					
					<p>I know that God is faithful to His Word, &ldquo;<em>I will bless those who bless you [Israel]</em>&rdquo; (Genesis 12:3).</p>
					
					<p>With blessings from the Holy Land,</p>
					
					<p>Yael Eckstein<br/>President & CEO </p>

					

				</div>

				<div v-else>

					<p v-html="acknowledgment.content"></p>

				</div>


				<hr>

			</div>

			<div class="modal" tabindex="-1" role="dialog" v-bind:class="{active: modal.upsell.status.isActive}">
				<div class="modal-dialog container" role="document">
					<div class="modal-content">
						<div class="modal-body color-block">
							<div class="row float-right">
								<button type="button" class="close" data-dismiss="modal" aria-label="Close" v-on:click="closeUpsell">
									<i class="fas fa-times"></i>
								</button>
							</div>

							<div class="row">
								<div class="col-12 col-md-6 offset-md-6 copy-block">
									<h2 class="modal-title">Multiply Your Blessings!</h2>
									<p>Thank you! We have processed your generous gift of ${{acknowledgment.payment.totalAmount}}! Increase your blessings today by committing to an additional monthly gift to ensure the needs of God’s chosen are cared for in the months to come.</p>

									<div class="form-group col-12 text-center">
										<label for="monthlyAmount">Monthly Amount</label>
										<!--<div class="float-container"><span class="suffix">$</span><input type="number" min="5" step=".01" placeholder="Amount" class="float-container" v-model.number="upsell.amount"></div>-->
										<div class="input-group">
											<div class="input-group-prepend">
												<span class="input-group-text">$</span>
											</div>
											<!--<input type="text" class="form-control" aria-label="Amount (to the nearest dollar)">-->
											<input type="number" placeholder="Amount" class="form-control text-center" v-model.number="form.upsell.amount" v-bind:min="acknowledgment.upsell.minimumAmount" required />
											<div class="input-group-append">
												<span class="input-group-text">.00</span>
											</div>
										</div>
										<div class="small mt-2 mb-2" for="datepicker">Starting on {{acknowledgment.upsell.startDate}}</div>
										<input id="datepicker" type="date" class="form-control text-center" v-if="acknowledgment.upsell.hasDateField" v-model="form.upsell.startDate" v-bind:min="minUpsellDate" v-bind:max="maxUpsellDate" required />
									</div>
									<div class="alert alert-danger text-center" v-show="modal.upsell.status.isSubmitted && modal.upsell.status.message != ''">{{modal.upsell.status.message}}</div>
									<div class="form-group col-12 text-center">
										<button type="button" class="btn btn-primary btn-block" v-on:click="postUpsell"><span style="font-size:24px">Yes!</span> <br>I'd like to give monthly</button>
										<button type="button" class="btn btn-block btn-link mt-2" v-on:click="closeUpsell">No, I will consider this in the future</button>
									</div>
								</div>
							</div>

						</div>
					</div>
				</div>
			</div>

		</div>

		

		<div class="container">
			<div class="alert alert-success text-center" role="alert">
				<h3 class="alert-heading">I Stand with the Jewish People</h3>
				<p class="text-left">Take the next step and show your unwavering support for Israel TODAY. Pledge your commitment by signing your name alongside Fellowship friends like you to send a strong message of solidarity to the people of Israel.</p>
				<a href="https://help.ifcj.org/survey/130/0" class="btn btn-outline-dark text-center">Pledge Here Today</a>
			</div>
			<div class="row fundraising-story" >

				<div class="col-12 text-center">
					<h3>{{stories[acknowledgment.language].header}}</h3>
				</div>
				
				<div class="col-sm-12 col-md-4">
					<article class="card">
						<div class="card-header">
							<span class="card-category">{{stories[acknowledgment.language].first.cardCategory}}</span>
							<div v-if="acknowledgment.language == 'en'">
								<a v-bind:href="[stories[acknowledgment.language].first.link]">
									<img v-bind:src="[stories[acknowledgment.language].first.imageURL]" data-site-region="thank-you-card" data-link-type="poverty-image" alt="Elderly woman brown sweater holding praying hands " class="img-fluid" loading="lazy" width="750" height="375">
								</a>
							</div>
							<div v-if="acknowledgment.language !== 'en'">
							<img v-bind:src="[stories[acknowledgment.language].first.imageURL]" data-site-region="thank-you-card" data-link-type="poverty-image" alt="Elderly woman brown sweater holding praying hands " class="img-fluid" loading="lazy" width="750" height="375">
							</div>
						</div>
						<div class="card-body">
							<h4>{{stories[acknowledgment.language].first.cardHeader}}</h4>
							<p>{{stories[acknowledgment.language].first.blurb}}</p>
						</div>
						<div v-if="acknowledgment.language == 'en'" class="card-footer">
							<a v-bind:href="[stories[acknowledgment.language].first.link]" class="btn btn-solid-primary" data-site-region="thank-you-card" data-link-type="poverty-button">{{stories[acknowledgment.language].first.buttonText}}</a>
						</div>
					</article>
				</div>

				<div class="col-sm-12 col-md-4">
					<article class="card">
						<div class="card-header">
							<span class="card-category">{{stories[acknowledgment.language].second.cardCategory}}</span>
							
							<div v-if="acknowledgment.language == 'en'">
								<a v-bind:href="[stories[acknowledgment.language].second.link]">
									<img v-bind:src="[stories[acknowledgment.language].second.imageURL]" data-site-region="thank-you-card" data-link-type="security-image" alt="Three young teenage girls watch bomb shelter being installed" class="img-fluid" loading="lazy" width="750" height="375">
								</a>
							</div>
							<div v-if="acknowledgment.language !== 'en'">
							<img v-bind:src="[stories[acknowledgment.language].second.imageURL]" data-site-region="thank-you-card" data-link-type="security-image" alt="Three young teenage girls watch bomb shelter being installed" class="img-fluid" loading="lazy" width="750" height="375">
							</div>
						</div>
						<div class="card-body">
							<h4>{{stories[acknowledgment.language].second.cardHeader}}</h4>
							<p>{{stories[acknowledgment.language].second.blurb}}</p>						
						</div>
						<div v-if="acknowledgment.language == 'en'" class="card-footer">
							<a v-bind:href="[stories[acknowledgment.language].second.link]" class="btn btn-solid-primary" data-site-region="thank-you-card" data-link-type="security-button">{{stories[acknowledgment.language].second.buttonText}}</a>
						</div>
					</article>
				</div>

				<div class="col-sm-12 col-md-4">
					<article class="card">
						<div class="card-header">
							<span class="card-category">{{stories[acknowledgment.language].third.cardCategory}}</span>
							<div v-if="acknowledgment.language == 'en'">
								<a v-bind:href="[stories[acknowledgment.language].third.link]">
									<img v-bind:src="[stories[acknowledgment.language].third.imageURL]" data-site-region="thank-you-card" data-link-type="aliyah-image" alt="Girl on tarmac with flags of Israel" class="img-fluid" loading="lazy" width="750" height="375">
								</a>
							</div>
							<div v-if="acknowledgment.language !== 'en'">
								<img v-bind:src="[stories[acknowledgment.language].third.imageURL]" data-site-region="thank-you-card" data-link-type="aliyah-image" alt="Girl on tarmac with flags of Israel" class="img-fluid" loading="lazy" width="750" height="375">
							</div>
						</div>
						<div class="card-body">
							<h4>{{stories[acknowledgment.language].third.cardHeader}}</h4>
							<p>{{stories[acknowledgment.language].third.blurb}}</p>
						</div>
						<div v-if="acknowledgment.language == 'en'" class="card-footer">
							<a v-bind:href="[stories[acknowledgment.language].third.link]" class="btn btn-solid-primary" data-site-region="thank-you-card" data-link-type="aliyah-button">{{stories[acknowledgment.language].third.buttonText}}</a>
						</div>
					</article>
				</div>
			</div>
		</div>

	</div>

</template>




<script>



import logos from '@/assets/data/logos.json';
import logosTest from '@/assets/data/logos-test.json';
import fundraisingStories from '@/assets/data/fundraising-stories.json';

export default {
	name: 'DonationAcknowledgment',
	data () {
		return {
			logos: this.$route.query.version == 2 ? logosTest : logos,
			stories: fundraisingStories,
			acknowledgment: {
				primaryId: this.$route.params.primaryId === undefined ? null : Number(this.$route.params.primaryId),
				secondaryId: this.$route.params.secondaryId === undefined ? null : Number(this.$route.params.secondaryId),
				language: 'en',
				pageTitle: 'Thank You',
				title: 'Thank You',
				content: '',
				isCrisis: false,
				mediaType: 0,
				media: '',
				payment: {
					totalAmount: null
				},
				upsell: {
					minimumAmount: null
				}
			},
			analytics_dataLayer: {},
			payment: {
				method: 3,
				details: {
					paymentToken: this.$route.query.token === undefined ? '' : this.$route.query.token,
					paymentId: this.$route.query.paymentId === undefined ? '' : this.$route.query.paymentId,
					payerId: this.$route.query.PayerID === undefined ? '' : this.$route.query.PayerID,
				}
			},
			form: {
				upsell: {
					amount: null,
					startDate: this.setDate(0)
				}
			},
			modal: {
				upsell: {
					status: {
						isActive: false,
						isSubmitted: false,
						isProcessing: false,
						isSuccess: null,
						message: ''
					}
				}
			}
		}
	},

	computed: {

		minUpsellDate () {

			return this.setDate(0);
		},
		maxUpsellDate () {

			return this.setDate(30);
		}
	},

	watch: {

		'form.upsell.startDate': function (date) {

			this.acknowledgment.upsell.startDate = new Date(date + 'T00:00').toLocaleDateString();
		}
	},

	mounted () {

		if (this.acknowledgment.primaryId !== null && this.acknowledgment.secondaryId !== null) {

			this.getAcknowledgment(this.acknowledgment.primaryId, this.acknowledgment.secondaryId);
		}
	},

	created () {
		
		if (sessionStorage.getItem('analytics_dataLayer') !== null) {

			let analytics_dataLayer = JSON.parse(sessionStorage.getItem('analytics_dataLayer'));

			sessionStorage.removeItem('analytics_dataLayer');

			this.analytics_dataLayer = Object.assign({}, this.analytics_dataLayer, analytics_dataLayer);

			window.dataLayer.push({ ecommerce: null });

			window.dataLayer.push({
				event: 'purchase',
				sessionId: this.analytics_dataLayer.sessionId,
				rb_session_id: this.analytics_dataLayer.rb_session_id,
				fullEmailAddress: this.analytics_dataLayer.fullEmailAddress,
				motivationCode:this.analytics_dataLayer.motivationCode,
				smsOptIn: this.analytics_dataLayer.smsOptIn,
				paymentMethod: this.analytics_dataLayer.paymentMethod,
				ecommerce: this.analytics_dataLayer.ecommerce
			}); 
			// eslint-disable-next-line
			window._vis_opt_queue.push(function() {_vis_opt_revenue_conversion(this.analytics_dataLayer.ecommerce.value);});

		}
		else{
			this.$newRelicAgent.noticeError('Analytics DataLayer Not Set in Storage', {
				component: 'DonationAcknowledgment',
				method: 'getItem',
				type: 'ApplicationException'
			});
		}

		if (sessionStorage.getItem('acknowledgment') !== null) {

			let acknowledgment = JSON.parse(sessionStorage.getItem('acknowledgment'));

			sessionStorage.removeItem('acknowledgment');

			this.acknowledgment = Object.assign({}, this.acknowledgment, acknowledgment);

			if (this.payment.details.paymentToken !== '') {

				this.putDonation(this.acknowledgment.donationKey);
			}

			if (this.acknowledgment.upsell.startDate !== false &&
				this.acknowledgment.payment.frequency == 1 &&
				this.payment.details.paymentToken === '' &&
				this.acknowledgment.payment.totalAmount < this.acknowledgment.upsell.maximumThreshold) {

				const currentDate = Date.now();

				if (currentDate >= this.acknowledgment.upsell.startDate && (currentDate < this.acknowledgment.upsell.endDate || this.acknowledgment.upsell.endDate === false)) {

					this.form.upsell.amount = this.getUpsellAmount();
					this.acknowledgment.upsell.startDate = new Date().toLocaleDateString();

					this.modal.upsell.status.isActive = true;

					window.dataLayer.push({
						event: 'ModalOpen',
						modalType: 'Upsell'
					});
				}
			}
		}
		else{
			this.$newRelicAgent.noticeError('Acknowledgment Session Not Set in Storage', {
				component: 'DonationAcknowledgment',
				method: 'getItem',
				type: 'ApplicationException'
			});
		}

		
	},

	methods: {

		closeUpsell () {

			this.modal.upsell.status.isActive = false;

			window.dataLayer.push({
				event: 'ModalClose',
				modalType: 'Upsell'
			});
		},

		setDate (days) {

			const date = new Date();

			if (days > 0) {

				date.setDate(date.getDate() + days);
			}

			return date.getFullYear() + '-' + ('0' + (date.getMonth() + 1 )).slice(-2) + '-' + ('0' + date.getDate()).slice(-2);
		},

		getUpsellAmount () {

			if (this.acknowledgment.payment.totalAmount > this.acknowledgment.upsell.minimumThreshold) {

				return Math.round(this.acknowledgment.payment.totalAmount * this.acknowledgment.upsell.percentage);
			}
			else {

				return this.acknowledgment.upsell.minimumAmount;
			}
		},

		getAcknowledgment (primaryId, secondaryId) {

			this.$http.get('/forms/donation/' + primaryId + '/' + secondaryId + '/acknowledgment')
				.then((response) => {

					this.acknowledgment = Object.assign({}, this.acknowledgment, response.data.acknowledgment);

					document.title = this.acknowledgment.pageTitle + ' | ' + document.title;
				})
				.catch ((error) => {

					this.$newRelicAgent.noticeError(error.response.data.message, {
						component: 'DonationAcknowledgment',
						method: 'getAcknowledgment',
						type: 'ServerException'
					});
				});
		},

		putDonation (donationKey) {

			this.$captcha.ready(() => {
				this.$captcha.execute(this.$captcha.siteKey, {action: 'DonationAcknowledgment'})
					.then((token) => {

						let config = {
							headers: {
								'reCAPTCHA-Token': token
							}
						};

						let payload = {
							donationKey: this.acknowledgment.donationKey,
							payment: {
								method: this.payment.method,
								frequency: this.acknowledgment.payment.frequency,
								details: this.payment.details
							},
							paypal: this.payment.details
						};

						this.$http.put('/forms/donation/' + donationKey, payload, config)
							.catch ((error) => {

								this.$newRelicAgent.noticeError(error.response.data.message, {
									component: 'DonationAcknowledgment',
									method: 'putDonation',
									type: 'ServerException'
								});
							});
					});
			});
		},

		postUpsell () {

			this.modal.upsell.status.isSubmitted = true;
			this.modal.upsell.status.isProcessing = true;

			window.dataLayer.push({
				event: 'ModalSubmit',
				modalType: 'Upsell'
			});

			if (this.form.upsell.amount == '' || this.form.upsell.amount < this.acknowledgment.upsell.minimumAmount) {

				this.modal.upsell.status.isProcessing = false;
				this.modal.upsell.status.isSuccess = false;

				this.message = 'The form could not be submitted. Amounts must be $5 or greater.';

				return false;
			}

			this.$captcha.ready(() => {
				this.$captcha.execute(this.$captcha.siteKey, {action: 'DonationUpsell'})
				.then((token) => {

					let config = {
						headers: {
							'reCAPTCHA-Token': token
						}
					};

					let payload = {
						donationKey: this.acknowledgment.donationKey,
						amount: this.form.upsell.amount,
						startDate: this.form.upsell.startDate
					};

					this.$http.post('/forms/donation/upsell', payload, config)
						.then((response) => {

							if (response.data.success) {

								this.modal.upsell.status.isActive = false;
								this.modal.upsell.status.isProcessing = false;
								this.modal.upsell.status.isSuccess = true;

								window.dataLayer.push({
									event: 'UpsellComplete',
									motivationCode: this.acknowledgment.motivationCode,
									ecommerce: {
										purchase: {
											actionField: {
												id: this.acknowledgment.donationKey,
												revenue: this.acknowledgment.payment.totalAmount + this.form.upsell.amount
											},
											products: [{
												id: this.acknowledgment.primaryId + '.' + this.acknowledgment.secondaryId,
												name: 'Monthly Upsell',
												price: this.acknowledgment.payment.totalAmount + this.form.upsell.amount,
												category: this.acknowledgment.projectCode,
												variant: 2,
												quantity: 1
											}]
										}
									}
								});
							}
							else {

								this.modal.upsell.status.isActive = true;
								this.modal.upsell.status.isProcessing = false;
								this.modal.upsell.status.isSuccess = false;

								this.message = response.data.message;

								this.$newRelicAgent.noticeError(response.data.message, {
									component: 'DonationAcknowledgment',
									method: 'postUpsell',
									type: 'UserException'
								});
							}
						})
						.catch((error) => {

							this.modal.upsell.status.isActive = true;
							this.modal.upsell.status.isProcessing = false;
							this.modal.upsell.status.isSuccess = false;

							this.message = 'There was an issue processing your request. Please contact Donor Support at (800) 486-8844.';

							this.$newRelicAgent.noticeError(error.response.data.message, {
								component: 'DonationAcknowledgment',
								method: 'postUpsell',
								type: 'ServerException'
							});
						});

				});
			});
		}
	}
}


</script>





<style lang="scss" scoped>
.modal {

	display: block;
	background-color: rgba(0,0,0,.5);
	opacity: 0;
	visibility: hidden;
	transition: all .15s ease-in-out;
	position: fixed;

	&.active {

		opacity: 1;
		visibility: visible;
	}

	.modal-title {

		margin-top: 2rem;
		margin-bottom: .5rem;
	}

	.modal-body {

		background-image: url('/public/static/images/acknowledgment/modal-image.jpg');
		background-repeat: no-repeat;
		border-radius: 5px;
	}

	.color-block {

		height: auto;
		max-height: 100%;
		background-color: #ffda99;
	}

	.close {

		position: absolute;
		top: 1rem;
		right: 1rem;
		z-index: 1000;
		font-size: 1.5rem;
	}

	.btn-link {

		font-size:  0.75rem;
	}

	#datepicker {

		padding-left:30px;
	}

	@media only screen and (max-width: 767) {

		.input-group {

			display: flex;
		}
		.modal-body {

			background:none;
			background-color: #ffda99;
		}
	}

	@media only screen and (max-width: 480px) {

		.modal-body {

			background:none;
			background-color: #ffda99;
		}
		.copy-block {

			margin-right: 0px;
		}

		.input-group {

			max-width: 400px;
			display: flex;
		}
	}

	@media only screen and (min-width: 576px) {

		.modal-dialog {

			max-width: 665px;
			margin: 1.75rem auto;
		}
	}
}

hr {
		height: 1px;
		border: 0;
		background-color: rgba(0,91,158,.8);
}

.card-category {
	font-family: roboto,sans-serif;
    font-size: 1rem;
    font-weight: 500;
    margin-bottom: 0.5rem;
}

</style>
